
import {defineComponent} from "vue";


import ProductList from "@/components/reservations/ProductList.vue";

export default defineComponent({
  name: "product-list",
  components: {
    ProductList,
  },
  setup() {
    return {

    };
  },
});
