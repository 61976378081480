
import {computed, defineComponent, onMounted, onUpdated, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "Hotel-Room-Type-Section",
    components: {
    },
    props: {
        propHotelPrice: {},
        propHotelCode: String,
        propRoomPrice: {},
        propPriceParams: {},
        propRoomPriceCode: String,
    },
    setup(props) {

      onUpdated(() => {
        changeCurSymbol();

      });
      onMounted(() => {
        changeCurSymbol();
      });

      function changeCurSymbol() {
        const priceParamsCur = document.getElementsByClassName("spanPriceParamsCur")
        for (let i=0; i<priceParamsCur.length; i++){
          let eachCur = priceParamsCur[i] as HTMLElement;
          if((props.propPriceParams as any).Cur==="TRY"){
            eachCur.innerText= "₺";
          }
          if((props.propPriceParams as any).Cur==="EUR"){
            eachCur.innerText= "€";
          }
          if((props.propPriceParams as any).Cur==="STG"){
            eachCur.innerText= "£";
          }

        }
      }



        /*function getAvailableLabel(prmItem) {
            if (prmItem.Availability==="AVAILABLE") {
                return "Müsait";
            }
            return "Müsait Değil";
        }*/

      function getAvailableLabel(prmItem) {

        if (prmItem !==undefined && prmItem.Availability==="AVAILABLE") {
          //(document.getElementById("panelsStayOpen-collapsePriceTable_"+props.propHotelCode+"_"+props.propRoomPriceCode)as HTMLInputElement).disabled = false;
          return "Müsait";
        }else if (prmItem !==undefined && prmItem.Availability==="ONREQUEST") {

          return "Sor Sat";
        }
        return "Müsait Değil";
      }
      function getAvailableClassName(prmItem) {
        //console.log("HotelDetails getAvailableClassName Availability " + prmItem.Availability);
        if (prmItem !==undefined && prmItem.Availability ==="AVAILABLE") {
          return "text-success";
        } else if (prmItem !==undefined && prmItem.Availability ==="ONREQUEST") {

          return "text-warning";
        }
        return "text-danger";
      }

        function click_HotelPrice() {

        console.log("RoomTypeSection click Hotel Price is start");
          changeCurSymbol();
            if (AppCore.prsCore_Flight.theStepsBasket.Step2===undefined) {AppCore.prsCore_Flight.theStepsBasket.Step2 = {};}

            AppCore.prsCore_Flight.theStepsBasket.Step2.HotelPrice = props.propHotelPrice;
            AppCore.prsCore_Flight.theStepsBasket.Step2.RoomPrice = props.propRoomPrice;

            AppCore.prsCore_Flight.funcSummary_PrsStepsBasket();

            //store.dispatch(Actions.FLIGHT_RESERVATION_STEPS_BASKET, theStepsBasket.value);


            let vvPayload = {
                "SearchCode" : AppCore.prsCore_Flight.ccvSearchCode,
                "PriceCode": (props.propRoomPrice as any).PriceCode,
                "Type": 'HOTEL'
            };

            console.log("RoomTypeSection vvPayload: "+JSON.stringify(vvPayload));

            store.dispatch(Actions.FLIGHT_RESERVATION_ADD, vvPayload);

            //console.log("theSearchParams: "+ JSON.stringify(AppCore.prsCore_Flight.theSearchParams));
            //console.log("theStepsBasket: "+ JSON.stringify(AppCore.prsCore_Flight.theStepsBasket));
        }

        return {
            click_HotelPrice,
            getAvailableLabel,
          getAvailableClassName,
            libTools,
        };
    },
});
