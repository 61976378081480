
import {defineComponent} from "vue";

import TableHeadRow from "@/components/appcomps/tables/TableHeadRow.vue";
import TableBodyRow from "@/components/appcomps/tables/TableBodyRow.vue";

export default defineComponent({
    name: "table-head-row",
    components: {
        TableHeadRow,
        TableBodyRow,
    },
    emits: ["on-rows-update"],
    props : {
        idcode: {type: String, required: true},
        data: { type: Array, required: true },
        header: { type: Array, required: true },
    },
    setup(props, {emit}) {

        const onRowsUpdate = () => {
            emit("on-rows-update");
        };

        return {
            onRowsUpdate,
        };
    },
});
