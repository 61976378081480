
import {defineComponent, ref, watch,} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";

export default defineComponent({
  name: "payment-history",
  components: {
  },

  props: {
    reservationID: String,
  },



  setup(props) {
    const arrReservationPaymentsList = ref([]);

    watch(
        () => props.reservationID,
        () => {
          store.dispatch(Actions.RESERVATION_PAYMENTS_LIST, {'ReservationID':props.reservationID}).then((prmData) => {
          //store.dispatch(Actions.RESERVATION_PAYMENTS_LIST, {}).then((prmData) => {
            arrReservationPaymentsList.value = prmData.data;
          })
        }
    );






    return {
      dateConvert,
      arrReservationPaymentsList,

    };
  },
});
