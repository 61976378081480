
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import paymentMethods from "@/core/data/paymentMethods";
import {dateConvert} from "@/core/filters/datatime";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import PaymentDistribution from "@/components/general-modals/collection/PaymentDistribution.vue";

interface srcItem {
  srcFromDate: string;
  srcToDate: string;
}


export default defineComponent({
  name: "collection-list",
  components: {
    PaymentDistribution,
  },
  setup() {
    onMounted(()=>{
      setDatePicker();

    });

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const arrPaymentList = ref([]);
    const arrReservationPaymentsList = ref([]);

    const srcItem = ref<srcItem>({
      srcFromDate:"",
      srcToDate: "",
    });

    function setDatePicker() {
      let tmpSrcFromDate = flatpickr("#srcFromDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcToDate = flatpickr("#srcToDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
    }



    function click_Search(){
      console.log("collections click search v1");
      if (!submitButtonRef.value) {
        return;
      }
      console.log("collections click search v2");
      const payload1 = {
        ProcessDateStart: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
        ProcessDateFinish: srcItem.value.srcToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcToDate) : "",
      };
      store.dispatch(Actions.PAYMENT_LIST, payload1).then((prmData) => {
        arrPaymentList.value = prmData.data;
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      }).catch((prmData) => {
        libTools.method.swalError("Tamam", "Hata Oluştu");
      });
      submitButtonRef.value.disabled = false;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      //console.log(submitButtonRef.value);

    }

    /*store.dispatch(Actions.RESERVATION_PAYMENTS_LIST, {PaymentID:""}).then((prmData) => {
      arrReservationPaymentsList.value = prmData.data;
    })*/


    store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

   /* store.dispatch(Actions.RESERVATIONS_LIST, {});
    const myReservationList = computed(() => {
      return store.getters.reservationsList;
    });*/

    const paymentID = ref<number>(0);
    function setItems(ID) {
      paymentID.value=ID;
    }




    return {
      libTools,
      paymentMethods,
      dateConvert,
      arrPaymentList,
      myAccountList,
      click_Search,
      srcItem,
      submitButtonRef,
      arrReservationPaymentsList,
      setItems,
      paymentID,
     // myReservationList,
    };
  },
});

