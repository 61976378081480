import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "text-start fw-bold fs-7 gs-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("thead", _hoisted_1, [
    _createElementVNode("tr", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (column, i) => {
        return (_openBlock(), _createElementBlock("th", {
          key: i,
          class: "m-0 p-1",
          style: _normalizeStyle(`${_ctx.getMinWith(column)}`)
        }, _toDisplayString(column.columnLabel), 5))
      }), 128))
    ])
  ]))
}