import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {CurRateInfo} from "@/store/modules/CurRateModule";

interface myvar {
    data: Array<string>;
}

export interface PaymentInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class PaymentModule extends VuexModule implements PaymentInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get getPaymentList(): myvar {
        return this.myList;
    }

    get getPaymentDetails(): Array<string> {
        return this.myDetails;
    }

    get getPaymentErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_PAYMENTS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_PAYMENTS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_PAYMENTS_DETAILS](payload) {
        this.myDetails = payload;
    }

    @Action
    [Actions.PAYMENT_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/payments/getList", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_PAYMENTS_LIST, payload);
                    resolve(payload);
                }).catch(({ response }) => {
                    reject(payload);
                });
        });
    }

    @Action
    [Actions.PAYMENT_INSERT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/payments/insert", payload)
                .then(({ data }) => {
                    payload.data = data;
                    console.log("PAYMENT_INSERT data: "+JSON.stringify(data));
                    if(data.status == 201) {
                        console.log("PAYMENT_INSERT resolve");
                        resolve(payload);
                    } else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.SET_PAYMENTS_ERROR, myError);
                        console.log("PAYMENT_INSERT reject");
                        reject(payload);
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Tahsilat Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.SET_PAYMENTS_ERROR, myError);
                    reject(payload);
                });
        });
    }

    @Action
    [Actions.PAYMENT_DELETE](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/payments/delete", payload)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.SET_PAYMENTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.SET_PAYMENTS_ERROR, myError);
                    reject();
                });
        });
    }

}