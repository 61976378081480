
import {defineComponent} from "vue";


import List from "@/components/reports/agency/checkout-based-monthly-agency-report/List.vue";

export default defineComponent({
  name: "checkout-based-monthly-agency-report",
  components: {
    List,
  },
  setup() {
    return {

    };
  },
});
