

import {defineComponent, ref} from "vue";
import {dateConvert} from "@/core/filters/datatime";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    setup() {
        let arrCars = ref([]);

        let theTransfer = ref({});
        let ccvOperationDate;
        let arrTransferOperations= ref([]);

        function initComp(prmCars){
            console.log("AddTransferOperations initComp");
            arrCars.value = prmCars.value;
        }



        function showComp(prmTranfer, prmOperationDate) {
            theTransfer.value = prmTranfer;
            ccvOperationDate = prmOperationDate;
            console.log("showComp Tranfer: "+JSON.stringify(theTransfer.value));

            arrTransferOperations.value = [];
            store.dispatch(Actions.TRANSFER_OPERATIONS_LIST, {OperationDate: dateConvert.method.convertToDateFormated(ccvOperationDate, "YYYY-MM-DD"),}).then((prmData) => {
                console.log("TRANSFER_OPERATIONS_LIST");
                console.log(prmData);
                if (prmData.data!==undefined) {
                    arrTransferOperations.value = prmData.data;
                }

            });
        }

        function click_SelectCarAdd(prmCar) {
            console.log("click_SelectCarAdd prmCar: "+JSON.stringify(prmCar));
            send_TransferOperation_Insert(theTransfer.value, prmCar);
        }

        function click_SelectTransferOperation(prmTransferOperation) {
            console.log("click_SelectTransferOperation prmTransferOperation: "+JSON.stringify(prmTransferOperation));
            send_TransferOperation_Add(theTransfer.value, prmTransferOperation);
        }

        function send_TransferOperation_Insert(prmTransfer, prmCar) {
            let vvPayload = {
                CompanyID: prmCar.CompanyID,
                TransferCarID: prmCar.ID,
                From: prmTransfer.From,
                To: prmTransfer.To,
                PaxCount: (Number(prmTransfer.ADTCount) + Number(prmTransfer.CHDCount) + Number(prmTransfer.INFCount) ),
                OperationDate: dateConvert.method.convertToDateFormated(ccvOperationDate, "YYYY-MM-DD"),
                Cost: 0,
                Cur: prmTransfer.Cur,
                ReservationProductID: prmTransfer.ID,
            };
            console.log("send_TransferOperation "+JSON.stringify(vvPayload));

            store.dispatch(Actions.TRANSFER_OPERATIONS_INSERT, vvPayload).then((prmData) => {
                //console.log(prmData);
                if (prmData.status===201) {
                    Swal.fire('Güncellendi!', 'Transfer Operasyonu ekleme işlemi tamamlandı.', 'success').then((result)=>{
                        location.reload();
                    });
                } else {
                    libTools.method.swalError("Tamam", "Hata Oluştu");
                }
            }).catch(() => {
                libTools.method.swalError("Tamam", "Hata Oluştu");
            });


        }

        function send_TransferOperation_Add(prmTransfer, prmTransferOperation) {
            let vvPayload = {
                ID: prmTransferOperation.ID,
                ReservationProductID: prmTransfer.ID,
            };
            console.log("send_TransferOperation "+JSON.stringify(vvPayload));

            store.dispatch(Actions.TRANSFER_OPERATIONS_ADD, vvPayload).then((prmData) => {
                console.log(prmData);
                if (prmData.status===200) {
                    Swal.fire('Güncellendi!', 'Transfer Operasyonu ekleme işlemi tamamlandı.', 'success').then((result)=>{
                        location.reload();
                    });
                } else {
                    libTools.method.swalError("Tamam", "Hata Oluştu");
                }
            }).catch(() => {
                libTools.method.swalError("Tamam", "Hata Oluştu");
            });


        }


        return {
            initComp,
            arrCars,
            showComp,
            click_SelectCarAdd,
            arrTransferOperations,
            click_SelectTransferOperation,
        };
    },

});

