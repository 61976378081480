import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-rowindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tbody", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row, i) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: i,
        class: _normalizeClass(`torg_tr_${_ctx.idcode}`),
        "data-rowindex": `${i}`
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (properties, j) => {
          return (_openBlock(), _createElementBlock("td", {
            key: j,
            class: _normalizeClass(`m-0 p-1 torg_td_${_ctx.idcode}`)
          }, [
            _renderSlot(_ctx.$slots, `${properties.columnName}`, { row: row }, () => [
              _createTextVNode(_toDisplayString(row[_ctx.prop]), 1)
            ])
          ], 2))
        }), 128))
      ], 10, _hoisted_1))
    }), 128))
  ]))
}