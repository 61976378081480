
import {defineComponent, onUpdated} from "vue";

export default defineComponent({
    name: "table-body-row",
    props : {
        idcode: {type: String, required: true},
        header: { type: Array, required: true },
        data: { type: Array, required: true },
    },
    emits: ["on-rows-update"],
    setup(props, { emit }) {

        onUpdated(()=>{
            emit("on-rows-update");
        });

        return {

        };
    },
});

