
import {computed, defineComponent, ref} from "vue";
import { dateConvert } from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {jsPDF} from "jspdf";
import UploadFile from "@/components/appcomps/uploadfiles/UploadFile.vue";
import SendEmail from "@/components/appcomps/sendemail/SendEmail.vue";
import store from "@/store";

export default defineComponent({
    name: "reservation-transfer-voucher-modal",
    components: {
        UploadFile,
        SendEmail,
    },
    setup() {

        var theReservation = ref({});
        var theTransfer = ref({});

        const refBtnExportToPdf = ref(null);
        const refBtnSendEmail = ref(null);
        const refFileUpload = ref(null);
        const refSendEmail = ref(null);

        var pWindow;

        function setTransfer(prmReservation, prmTransfer) {
            theReservation.value = prmReservation.value;
            theTransfer.value = prmTransfer;
        }

        function printModal() {

            var divContents = document.getElementById("divTransfer_Voucher_AllPrintable").innerHTML;
            pWindow = window.open('', '', '');
            pWindow.document.write('<html><body>');
            pWindow.document.write(divContents);
            pWindow.document.write('</body></html>');
            pWindow.document.close();

            setTimeout(()=>{pWindow.print();}, 500);
        }

        function click_ExportToPdf(prmType, prmMailTo) {
            let elTarget = document.getElementById("divTransfer_Voucher_AllPrintable");
            elTarget.innerHTML = libTools.method.convertTurkishToEnglish(elTarget.innerHTML);

            if (prmType==="Export") {
                refBtnExportToPdf.value.disabled = true;
                refBtnExportToPdf.value.setAttribute("data-kt-indicator", "on");
            } else {
                refBtnSendEmail.value.disabled = true;
                refBtnSendEmail.value.setAttribute("data-kt-indicator", "on");
            }


            setTimeout(()=>{ exportToPdf(prmType, prmMailTo); }, 500);
        }

        function click_SendEmail() {
            (refSendEmail.value as any).showComp("");
        }

        function emailEntered(prmEmail) {
            click_ExportToPdf("SendEmail", prmEmail);
        }

        function exportToPdf(prmType, prmMailTo) {
            const doc = new jsPDF('l', 'mm', [1150, 1100]);
            let elTarget = document.getElementById("divTransfer_Voucher_AllPrintable");
            //let elNew = document.createElement("div");
            //elNew.innerHTML = libTools.method.convertTurkishToEnglish(vvText.innerHTML);
            doc.html(elTarget,
                {
                    callback: function(doc) {
                        let vvRes = theReservation.value as any;
                        let vvTransfer = theTransfer.value as any;
                        let vvDirName = vvRes.VoucherNo.replaceAll(".", "-");
                        let vvFileName = "TransferVoucher"+vvRes.VoucherNo.replaceAll(".", "_")+vvTransfer.ID+".pdf";
                        let vvMailTo = prmMailTo;
                        let vvContent = "Merhabalar, Transfer Voucher ("+vvTransfer.ID+") bilgileri ektedir";
                        let vvToName = "Transfer Voucher "+vvTransfer.ID;
                        let vvFromName = "Transfer Voucher "+vvTransfer.ID;
                        let vvSubject = "Transfer Voucher "+vvTransfer.ID;

                        if (prmType==="Export") {
                            doc.save(vvFileName);
                            refBtnExportToPdf.value.disabled = false;
                            refBtnExportToPdf.value.setAttribute("data-kt-indicator", "off");
                        }
                        if (prmType==="SendEmail") {
                            var vvFile = doc.output('blob');
                            (refFileUpload.value).addFileAndSendEmail(vvFile, vvDirName, vvFileName, "HOTELVOUCHER", vvMailTo, vvToName, vvFromName, vvSubject, vvRes.AccountID, vvRes.ID, vvContent);

                        }

                    },
                    x: 10,
                    y: 4,
                    image: { type: "png", quality: 0.98 }, // as per req
                    html2canvas: { letterRendering: true, useCORS: true },
                }
            );
        }

        function onSuccessUploadFile() {
            refBtnSendEmail.value.disabled = false;
            refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
        }

        function onErrorUploadFile(prmErrorCode) {
            refBtnSendEmail.value.disabled = false;
            refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
        }

        const dataHotelList = computed(() => {
          let dataComputed = store.getters.hotelListTo;
          return dataComputed;
        });

        return {
            dateConvert, libTools,
            setTransfer,
            theReservation,
            theTransfer,
            printModal,
            refBtnExportToPdf, click_ExportToPdf,
            refFileUpload, refBtnSendEmail, refSendEmail,
            onSuccessUploadFile, onErrorUploadFile,
            click_SendEmail, emailEntered,
            dataHotelList,
        };
    }
});
