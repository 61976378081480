import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import CommonModules from "@/store/modules/CommonModules";
import Files from "@/store/modules/FilesModules";
import ExcelParseModule from "@/store/modules/ExcelParseModule";
import FileUploadModules from "@/store/modules/FileUpload";
import UserModule from "@/store/modules/UserModule";
import AccountModule from "@/store/modules/AccountModule";
import AccountProductModule from "@/store/modules/AccountProductModule";
import HotelModule from "@/store/modules/HotelModule";
import HotelRoomsModule from "@/store/modules/HotelRooms";
import HotelConceptsModule from "@/store/modules/HotelConcepts";
import HotelRoomTypesModule from "@/store/modules/HotelRoomTypes";
import HotelConceptTypesModule from "@/store/modules/HotelConceptTypes";
import HotelFactSheetsModule from "@/store/modules/HotelFactSheets";
import HotelFactSheetItemsModule from "@/store/modules/HotelFactSheetItems";
import HotelOverCommissionModule from "@/store/modules/HotelOverCommissionModules";
import HotelProfitsModule from "@/store/modules/HotelProfitsModules";
import HotelKickBacksModule from "@/store/modules/HotelKickBacksModules";
import HotelAvailableModule from "@/store/modules/HotelAvailableModules";
import HotelAllocationsModule from "@/store/modules/HotelAllocationsModules";
import HotelAdModule from "@/store/modules/HotelAdModules";
import HotelActionsModules from "@/store/modules/HotelActionsModules";
import HotelRoomPriceModules from "@/store/modules/HotelRoomPrice";
import CompaniesModule from "@/store/modules/CompanyModules";
import CompaniesBranchesModule from "@/store/modules/CompanyBranchesModules";
import CompaniesDepartmentsModule from "@/store/modules/CompanyDepartmentsModules";
import CompaniesTitlesModule from "@/store/modules/CompanyTitlesModules";
import SystemUserGroupModule from "@/store/modules/SystemUserGroupModules";
import HotelChainModules from "@/store/modules/HotelChainModules";
import FactSheetsModule from "@/store/modules/FactSheets";
import FactSheetItemsModule from "@/store/modules/FactSheetItems";
import AccountTagsModule from "@/store/modules/AccountTagsModules";
import ReservationTagsModule from "@/store/modules/ReservationTagsModules";
import PriceListsModule from "@/store/modules/PriceListsModule";
import PriceListItemsModule from "@/store/modules/PriceListItemsModule";
import MiceRequestsModule from "@/store/modules/MiceRequstsModule";
import SuppliersModule from "@/store/modules/SuppliersModule";
import AirlineModule from "@/store/modules/AirlineModule";
import AirlineClassesModule from "@/store/modules/AirlineClassesModule";
import AirportsModule from "@/store/modules/AirportsModule";
import FlightExtrasModule from "@/store/modules/FlightExtrasModule";
import BanksModule from "@/store/modules/BanksModule";
import BankPosModule from "@/store/modules/BankPosModule";
import BankPosInstallmentModule from "@/store/modules/BankPosInsallmentModule";
import BankAccountsModule from "@/store/modules/BankAccountsModule";
import CompanyCreditCardModule from "@/store/modules/CompanyCreditCardModule";
import CurRateModule from "@/store/modules/CurRateModule";
import ReservationsModule from "@/store/modules/ReservationsModule";
import ReservationProductsModule from "@/store/modules/ReservationProductsModule";
import MakeManuelReservationsModule from "@/store/modules/MakeManuelReservationsModule";
import TagsModule from "@/store/modules/TagsModules";
import RegionsModules from "@/store/modules/RegionsModules";
import MarketsModules from "@/store/modules/MarketsModules";
import LanguagesModules from "@/store/modules/LanguagesModules";
import FlightReservationModule from "@/store/modules/FlightReservationModule";

import HotelTest from "@/store/modules/HotelTest";

import TransferCompanyModule from "@/store/modules/TransferCompanyModule";
import TransferCarsModule from "@/store/modules/TransferCarsModule";
import TransferLocationsModule from "@/store/modules/TransferLocationsModule";
import TransferCarCostsModule from "@/store/modules/TransferCarCostsModule";
import TransfersModule from "@/store/modules/TransfersModules";
import TransferPricesModule from "@/store/modules/TransferPricesModule";
import PaymentModule from "@/store/modules/PaymentModule";
import ReservationPaymentModules from "@/store/modules/ReservationPaymentModules";
import RemarkModule from "@/store/modules/RemarkModule";
import TransferOperationsModule from "@/store/modules/TransferOperationsModule";
import ApiModule from "@/store/modules/ApiModule";
import PosProcessModule from "@/store/modules/PosProcessModule";
import Invoices from "@/store/modules/InvoicesModule";
import AccountingCodeModule from "@/store/modules/AccountingCodeModules";
import ExportToPdfModule from "@/store/modules/ExportToPdfModule";
import InvoiceItemsModule from "@/store/modules/InvoiceItemsModule";
import VatRatesModule from "@/store/modules/VatRatesModule";
import AccountingCostCentersModule from "@/store/modules/AccountingCostCentersModule";
import AccountKickBackModule from "@/store/modules/AccountKickBackModule";
import FlightSupplierProfitsInfo from "@/store/modules/FlightSupplierProfitsModules";
import EmailSentsModule from "@/store/modules/EmailSentsModule";
import ReportsModule from "@/store/modules/ReportsModule";
import AccountGroupModule from "@/store/modules/AccountGroupModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    Files,
    ExcelParseModule,
    UserModule,
    AccountModule,
    AccountProductModule,
    HotelModule,
    HotelRoomsModule,
    HotelConceptsModule,
    HotelRoomTypesModule,
    HotelConceptTypesModule,
    HotelFactSheetsModule,
    HotelFactSheetItemsModule,
    HotelOverCommissionModule,
    HotelProfitsModule,
    HotelKickBacksModule,
    HotelAvailableModule,
    HotelAllocationsModule,
    HotelAdModule,
    HotelActionsModules,
    HotelRoomPriceModules,
    CommonModules,
    FileUploadModules,
    CompaniesModule,
    CompaniesBranchesModule,
    CompaniesDepartmentsModule,
    CompaniesTitlesModule,
    SystemUserGroupModule,
    HotelChainModules,
    FactSheetsModule,
    FactSheetItemsModule,
    AccountTagsModule,
    ReservationTagsModule,
    PriceListsModule,
    PriceListItemsModule,
    MiceRequestsModule,
    SuppliersModule,
    AirlineModule,
    AirlineClassesModule,
    AirportsModule,
    FlightExtrasModule,
    BanksModule,
    BankPosInstallmentModule,
    BankPosModule,
    BankAccountsModule,
    CompanyCreditCardModule,
    CurRateModule,
    ReservationsModule,
    ReservationProductsModule,
    MakeManuelReservationsModule,
    TagsModule,
    RegionsModules,
    MarketsModules,
    LanguagesModules,
    FlightReservationModule,
    HotelTest,
    TransferCompanyModule,
    TransferCarsModule,
    TransferLocationsModule,
    TransferCarCostsModule,
    TransfersModule,
    TransferPricesModule,
    PaymentModule,
    ReservationPaymentModules,
    RemarkModule,
    TransferOperationsModule,
    ApiModule,
    PosProcessModule,
    Invoices,
    AccountingCodeModule,
    ExportToPdfModule,
    InvoiceItemsModule,
    VatRatesModule,
    AccountingCostCentersModule,
    AccountKickBackModule,
    FlightSupplierProfitsInfo,
    EmailSentsModule,
    ReportsModule,
    AccountGroupModule,
  },
});

export default store;
