
import {computed, defineComponent, onMounted, ref} from "vue";
import {dateConvert} from "@/core/filters/datatime";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import AddTransferOperationModal from "@/components/operations/transfers/transferoperations/AddTransferOperationModal.vue";
import {read, utils, writeFileXLSX } from "xlsx";

export default defineComponent({

    components: {
        AddTransferOperationModal,
    },
    setup() {

      onMounted(()=>{
        //setTimeout(getTotalPaxNumber, 3000);
      });

        const refComp_AddTransferOperationModal = ref<null>();

        let arrGroupedTransfers = ref ([]);
        let arrTransfers = ref([]);
        let arrCars = ref([]);
        let ccvOperationDate;

        function initComp(prmStartDate, prmCars){
            console.log("TransferList initComp");
            arrCars.value = prmCars.value;
            (refComp_AddTransferOperationModal.value as any).initComp(arrCars);
            sendFilterTransfers(prmStartDate);
        }

        const payload = {
          ID: "21"
        }

        store.dispatch(Actions.TRANSFER_OPERATIONS_DETAILS, payload);
        const dataTransferOperationDetails = computed(() => {
          let dataComputed = store.getters.getTransferOperationsDetails;
          return dataComputed;
        });

        function setOperationDate(prmOperationDate) {
            ccvOperationDate = prmOperationDate;
            console.log("setOperationDate "+ccvOperationDate);
        }

        function sendFilterTransfers(prmStartDate) {
            console.log("sendFilterTransfers START prmStartDate: "+prmStartDate);
            arrGroupedTransfers.value = [];
            arrTransfers.value = [];
            let vvPayload = {is_active: "active", ProductType: "TRANSFER", StartDate:dateConvert.method.convertToDateFormated(prmStartDate, "YYYY-MM-DD")};
            console.log("sendFilterTransfers vvPayload: "+JSON.stringify(vvPayload));
            store.dispatch(Actions.RESERVATION_PRODUCTS_OPERATION_TRANSFER_LIST, vvPayload).then((prmData) => {
                //console.log(prmData);
                if (prmData.data!==undefined) {
                    arrTransfers.value = prmData.data;
                    setGroupedTransfers();
                    getTotalPaxNumber();
                }
            });
        }

        function setGroupedTransfers() {
            arrGroupedTransfers.value = [];
            let arr1 = [];
            let arr2 = [];
            for (let i=0; i<arrTransfers.value.length; i++) {
                let eachTransfer = arrTransfers.value[i] as any;
                if (eachTransfer.RemarksVC3==="ARRIVAL") {
                    arr1.push(eachTransfer);
                }
                if (eachTransfer.RemarksVC3==="RETURN") {
                    arr2.push(eachTransfer);
                }
            }
            arrGroupedTransfers.value.push(arr1);
            arrGroupedTransfers.value.push(arr2);
        }

        function click_SelectTranfer(prmTransfer) {
            (refComp_AddTransferOperationModal.value as any).showComp(prmTransfer, ccvOperationDate);
        }

        function getGroupHeader(prmIndex) {
            if (prmIndex===0) {
                return "Gelen Yolcular";
            }
            return "Dönen Yolcular";
        }

        function click_ExportToExcel() {
            exportToExcel();
        }

        function exportToExcel() {
            const ws1 = utils.json_to_sheet(convertExcelSheet(arrGroupedTransfers.value[0]));
            const ws2 = utils.json_to_sheet(convertExcelSheet(arrGroupedTransfers.value[1]));
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws1, "Geliş");
            utils.book_append_sheet(wb, ws2, "Dönüş");
            writeFileXLSX(wb, "List.xlsx");
        }

        function convertExcelSheet(prmTransfers) {
            let rv=[];
            for (let i=0; i<prmTransfers.length; i++) {
                let eachTransfer = prmTransfers[i];
                console.log("convertExcelSheet " + JSON.stringify(eachTransfer));
                if(eachTransfer.RemarksEnum1!=="CANCEL CONFIRM" && eachTransfer.deleted_by===null){
                  const arrPaxes = libTools.method.getRemarksJSONPaxes(eachTransfer.RemarksJSON1)

                    for(let j=0; j<arrPaxes.length; j++){
                      if((arrPaxes[j] as any).PaxName!==undefined){
                        let eachPax = arrPaxes[j];
                        let vvNew = {
                          "Voucher No": eachTransfer.VoucherNo,
                          "Transfer Adı": eachTransfer.RemarksVC2,
                          "Yolcu": libTools.method.getAllPaxNameSurname([eachPax]),
                          //"Yolcu": libTools.method.getRemarksJSONPaxes(eachTransfer.RemarksJSON1),
                          "Acente": eachTransfer.AccountTitle,
                          "İletişim Numarası": libTools.method.getParsedObj(eachTransfer.Contact).Phone,
                          "UçuşNo": libTools.method.getRemarksJSONFlightTimes(eachTransfer.RemarksJSON3).FlightNo,
                          "Otel": getHotelTitle(libTools.method.getParsedObj(eachTransfer.RemarksJSON3).HotelID),
                          "Tür": libTools.method.getParsedObj(eachTransfer.RemarksJSON3).VIPType,
                          "Nereden": libTools.method.getParsedObj(eachTransfer.RemarksJSON3).TransferLocationAName,
                          "Nereye": libTools.method.getParsedObj(eachTransfer.RemarksJSON3).TransferLocationBName,
                          "Uçak Kalkış Saati": libTools.method.getRemarksJSONFlightTimes(eachTransfer.RemarksJSON3).FlightDepTime,
                          "Uçak Varış Saati": libTools.method.getRemarksJSONFlightTimes(eachTransfer.RemarksJSON3).FlightArrTime,
                          "Transfer Tarihi": dateConvert.method.convertToDateFormated(eachTransfer.StartDate, "DD-MM-YYYY"),
                          "Transfer Saati": libTools.method.getParsedObj(eachTransfer.RemarksJSON3).TransferTime,
                          "Araç Plakası": eachTransfer.PlateNumber,
                          "Araç Şirketi": eachTransfer.TransferCompanyTitle,
                          "Araç Tipi": libTools.method.getValueOrEmptyString(eachTransfer.TransferCarType) + " " + libTools.method.getValueOrEmptyString(eachTransfer.TransferCarTitle),
                        };
                        rv.push(vvNew);
                      }
                    }


                }

            }
            return rv;
        }

        const objTotalByTransferProduct = ref({TransferProductTotalPriceTRY:0, TransferProductTotalPriceEUR:0, TransferProductTotalPriceUSD:0, TransferProductTotalPriceSTG:0, TransferPaxCount:0,});
        function getTotalPaxNumber() {
          const rv = 0;
          objTotalByTransferProduct.value.TransferProductTotalPriceTRY=0;
          objTotalByTransferProduct.value.TransferProductTotalPriceEUR=0;
          objTotalByTransferProduct.value.TransferProductTotalPriceUSD=0;
          objTotalByTransferProduct.value.TransferProductTotalPriceSTG=0;
          objTotalByTransferProduct.value.TransferPaxCount=0;
          for (let i=0; i<arrTransfers.value.length; i++){
            let each = arrTransfers.value[i];
            if(each.RemarksEnum1!=='CANCEL CONFIRM' && each.deleted_by===null){
              objTotalByTransferProduct.value.TransferPaxCount += (Number(each.ADTCount)+Number(each.CHDCount)+Number(each.INFCount));
              if(each.Cur==='TRY'){
                objTotalByTransferProduct.value.TransferProductTotalPriceTRY += parseFloat(each.TotalPrice);
              }
              if(each.Cur==='EUR'){
                objTotalByTransferProduct.value.TransferProductTotalPriceEUR += parseFloat(each.TotalPrice);
              }
              if(each.Cur==='USD'){
                objTotalByTransferProduct.value.TransferProductTotalPriceUSD += parseFloat(each.TotalPrice);
              }
              if(each.Cur==='STG'){
                objTotalByTransferProduct.value.TransferProductTotalPriceSTG += parseFloat(each.TotalPrice);
              }
            }


          }
          return rv;
        }

        function  getHotelTitle(prmHotelID){
            for (let i=0; i<arrTransfers.value.length; i++){
                let each = arrTransfers.value[i];
                if(libTools.method.getParsedObj(each.RemarksJSON3).HotelID===prmHotelID){
                  for(let i=0; i<dataHotelList.value.length; i++){
                    let eachdata = dataHotelList.value[i];
                    if(eachdata.ID===prmHotelID){
                      return eachdata.Title;
                    }
                  }

                  //return dataHotelList.value;
                }
            }
        }


        function getTransferPaxes (prmData){
          for(let i=0; i<arrTransfers.value.length; i++){
            let each=arrTransfers.value[i];

            if(each.ReservationID===prmData.ReservationID){
              return libTools.method.getAllPaxNameSurname(libTools.method.getRemarksJSONPaxes(each.RemarksJSON1))



              /*arrPaxes.push(libTools.method.getRemarksJSONPaxes(each.RemarksJSON1));
             // return arrPaxes;
              for(let i=0; i<arrPaxes.length; i++){

                let eachPax = arrPaxes[i];
                for(let i=0; i<eachPax.length; i++){
                  let eachT = eachPax[i];
                  return eachT.PaxName+" "+eachT.PaxSurname;

                }

              }*/
            }
          }

        }

        const dataHotelList = computed(() => {
          let dataComputed = store.getters.hotelListTo;
          return dataComputed.data;
        });
        store.dispatch(Actions.HOTEL_LIST_ALL_TO, {'CallFrom': "TO",});

        return {
            libTools, dateConvert,
            initComp,
            arrGroupedTransfers, arrTransfers,
            sendFilterTransfers,
            refComp_AddTransferOperationModal,
            click_SelectTranfer,
            setOperationDate,
            getGroupHeader,
            click_ExportToExcel,
            dataHotelList,
            getHotelTitle,
          objTotalByTransferProduct,
            getTransferPaxes,
          dataTransferOperationDetails,
        };
    },
});
