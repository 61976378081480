import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

interface myvar {
    data: Array<string>;
}

export interface AccountGroupInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class AccountGroupModule extends VuexModule implements AccountGroupInfo {

    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get getAccountGroupList(): myvar {
        return this.myList;
    }

    get getAccountGroupDetails(): Array<string> {
        return this.myDetails;
    }

    get getAccountGroupErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.ACCOUNT_GROUP_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_ACCOUNT_GROUP_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_ACCOUNT_GROUP_DETAILS](payload) {
        this.myDetails = payload;
    }

    @Action
    [Actions.ACCOUNT_GROUP_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountgroup/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_ACCOUNT_GROUP_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.ACCOUNT_GROUP_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountgroup/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_ACCOUNT_GROUP_DETAILS, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.ACCOUNT_GROUP_INSERT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountgroup/insert", payload)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.ACCOUNT_GROUP_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.ACCOUNT_GROUP_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.ACCOUNT_GROUP_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountgroup/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.ACCOUNT_GROUP_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Acente Grubu Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.ACCOUNT_GROUP_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.ACCOUNT_GROUP_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountgroup/update", credentials)
                .then(({ data }) => {
                    if (data.status == 200) {
                        resolve();
                    } else {
                        const myError = [
                            "ERROR: " + data.messages + " (" + data.code + ")",
                        ];
                        this.context.commit(Mutations.ACCOUNT_GROUP_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = [
                        "ERROR: Acente Grubu güncelleme işlemi sırasında beklenmedik bir hata oluştu.",
                    ];
                    this.context.commit(Mutations.ACCOUNT_GROUP_ERROR, myError);
                    reject();
                });
        });
    }


}