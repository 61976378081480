
import {defineComponent} from "vue";


import List from "@/components/reports/agency/agency-based-sales-report/List.vue";

export default defineComponent({
  name: "agency-based-sales-report",
  components: {
    List,
  },
  setup() {
    return {

    };
  },
});
