
import {defineComponent, ref,} from "vue";

export default defineComponent({
    name: "reservation-hotel-update-modal",
    components: {
    },

  methods: {
    setContent(prmHotelInfo) {
      //console.log("ReservationHotelUpdateModal setContent prmHotelInfo: "+JSON.stringify(prmHotelInfo));
      this.setContentInner(prmHotelInfo);
    },
  },
    setup() {

      var theReservation = ref({});

      function setContentInner(prmReservation) {
        theReservation.value = prmReservation;
        //console.log("ReeservationHotelAddModal setContent "+JSON.stringify(prmReservation.value));
      }

        return {
          theReservation,
          setContentInner,
        };
    }


});

