import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableHeadRow = _resolveComponent("TableHeadRow")!
  const _component_TableBodyRow = _resolveComponent("TableBodyRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createVNode(_component_TableHeadRow, {
        idcode: _ctx.idcode,
        header: _ctx.header
      }, null, 8, ["idcode", "header"]),
      _createVNode(_component_TableBodyRow, {
        idcode: _ctx.idcode,
        header: _ctx.header,
        data: _ctx.data,
        onOnRowsUpdate: _ctx.onRowsUpdate
      }, _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (_, name) => {
          return {
            name: name,
            fn: _withCtx(({ row: item }) => [
              _renderSlot(_ctx.$slots, name, { row: item })
            ])
          }
        })
      ]), 1032, ["idcode", "header", "data", "onOnRowsUpdate"])
    ])
  ]))
}