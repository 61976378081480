
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AddNewAccountGroup from "@/components/account/AddNewAccountGroup.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import UpdateAccountGroup from "@/components/account/UpdateAccountGroup.vue";

export default defineComponent({
    components: {
      AddNewAccountGroup,
      UpdateAccountGroup,
    },
    setup() {

      store.dispatch(Actions.ACCOUNT_GROUP_LIST, {});
      const dataAccountGrupList = computed(() => {
        return store.getters.getAccountGroupList;
      });

      const deleteAccountGroup = (ID) => {
        store.dispatch(Actions.ACCOUNT_GROUP_LIST, {});
        Swal.fire({
          title: 'Emin misiniz?',
          text: "Kaydi silmek işlemi geri alınamaz.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Kaydı Sil',
          cancelButtonText: `Vazgeç`,
          confirmButtonColor: '#9c1d1d',
          cancelButtonColor: '#3085d6',
        }).then(function (result) {
          if (result.isConfirmed) {
            const payload1 = {
              ID: ID,
            }
            store.dispatch(Actions.ACCOUNT_GROUP_DELETE, payload1)
                .then(() => {
                  store.dispatch(Actions.ACCOUNT_GROUP_LIST, {});
                  Swal.fire(
                      'Silindi!',
                      'Kayit silme işlemi tamamlandı.',
                      'success'
                  )
                }).catch(() => {
              Swal.fire({
                text: store.getters.getAccountGroupErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });

          }
        });
      };

      const itemID = ref<number>(0);
      function setItems(ID) {
        itemID.value=ID;
      }


      return {
        libTools,
        setItems,
        itemID,

        dataAccountGrupList, deleteAccountGroup,
      };

    },
});
