
import {computed, defineComponent, onMounted, ref,} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import {Field} from "vee-validate";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";
import flatpickr from "flatpickr";
import {Turkish} from "flatpickr/dist/l10n/tr";
import 'flatpickr/dist/flatpickr.css';
import {utils, writeFileXLSX} from "xlsx";


interface intSearchVals {
  srcMarket: number;
  srcProcessDateStart: string;
  srcProcessDateFinish: string;
  srcStartDateStart: string;
  srcStartDateFinish: string;
  srcFinishDateStart: string;
  srcFinishDateFinish: string;
  srcAccount: number;
}

export default defineComponent({
  name: "agency-based-sales-report",
  components: {
    Field,
  },

  setup() {

    onMounted(() => {
      setDatePicker();
      search();
      setCurrentPageBreadcrumbs("Acente Bazlı Satış Raporu", ["Raporlar", "Liste"]);
    });

    //const arrTotalsByAccount = ref ([]);
    const arrAccountSales = ref ([]);


    const intSearchVals = ref<intSearchVals>({
      srcMarket: 0,
      srcAccount: 0,
      srcProcessDateStart: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcProcessDateFinish: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcStartDateStart: "",
      srcStartDateFinish: "",
      srcFinishDateStart: "",
      srcFinishDateFinish: "",

    });

    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });

    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    const myAccountsTitle = computed(() => {
      let tmpArray = [];
      if (myAccountList.value.data !== undefined) {
        for (var i of myAccountList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });

    const getAccounts = () => {
      let myAccounts = document.getElementById("srcAccounts") as HTMLInputElement;
      let myPayload = {};
      if (myAccounts.value.length == 3) {
        myPayload = {
          'Title': myAccounts.value
        }
        store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
      }
    }

    const filterAccounts = (val) => {
      if (myAccountList.value.data != null) {
        return (myAccountList.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return true;
      }
    };


    var fpickStartDateStart;
    var fpickStartDateFinish;
    var fpickFinishDateStart;
    var fpickFinishDateFinish;

    function setDatePicker() {
      let tmpSrcFromDate = flatpickr("#srcProcessDateStart", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcToDate = flatpickr("#srcProcessDateFinish", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});

      fpickStartDateStart = flatpickr("#src_StartDateStart", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickStartDateFinish = flatpickr("#src_StartDateFinish", {"locale": Turkish, dateFormat: "d-m-Y (D)", });

      fpickFinishDateStart = flatpickr("#src_FinishDateStart", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickFinishDateFinish = flatpickr("#src_FinishDateFinish", {"locale": Turkish, dateFormat: "d-m-Y (D)", });

    }



    const search = () => {
      arrAccountSales.value = [];
      let myAccount = document.getElementById("srcAccounts") as HTMLInputElement;
      const payload1 = {
        MarketID:intSearchVals.value.srcMarket,
        AccountID : myAccount.value ? filterAccounts(myAccount.value)[0].ID : "",
        ProcessStartDate: intSearchVals.value.srcProcessDateStart ? dateConvert.method.flatpickerToDateYM(intSearchVals.value.srcProcessDateStart) : "",
        ProcessFinishDate: intSearchVals.value.srcProcessDateFinish ? dateConvert.method.flatpickerToDateYM(intSearchVals.value.srcProcessDateFinish) : "",
        StartDateStart: libTools.method.getSelectedDateFromFlatPicker(fpickStartDateStart, 0),
        StartDateFinish: libTools.method.getSelectedDateFromFlatPicker(fpickStartDateFinish, 0),
        FinishDateStart: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDateStart, 0),
        FinishDateFinish: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDateFinish, 0),
      }

      store.dispatch(Actions.ACCOUNT_SALE_LIST, payload1).then((prmData) => {
        arrAccountSales.value = prmData;
      });

    }

    function click_ExportToExcel() {
      exportToExcel();
    }

    function exportToExcel() {
      const ws1 = utils.json_to_sheet(convertExcelSheet(arrAccountSales.value));
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws1, "Liste");
      writeFileXLSX(wb, "Agency_Based_Sales_Report.xlsx");
    }

    function convertExcelSheet(prmReports) {
      let rv=[];
      for (let i=0; i<prmReports.length; i++) {
        let eachReport = prmReports[i];
        let vvNew = {
          "Acente Adı": eachReport.AccountTitle,
          "Rezervasyon Sayısı": eachReport.ReservationCount,
          "Oda Sayısı": eachReport.RoomCount,
          "Bilet Sayısı": eachReport.FlightCount,
          "Transfer Sayısı": eachReport.TransferCount,
          "Toplam Maliyet Fiyatı": eachReport.TotalCurCost,
          "Toplam Acente Fiyatı": eachReport.TotalAccountPrice,
          "Toplam Satış Fiyatı": eachReport.TotalPrice,
          "Toplam Komisyon": eachReport.TotalCommission,
          "Toplam Kâr": eachReport.TotalProfit,
          "Kur": eachReport.SaleCur,
        };
        rv.push(vvNew);
      }
      return rv;
    }


    return {
      Turkish,
      MasterConfig,
      dateConvert,
      libTools,
      search,
      intSearchVals,
      //arrTotalsByAccount,
      myMarkets,
      getAccounts,
      myAccountList,
      myAccountsTitle,
      click_ExportToExcel,
      arrAccountSales,
    }
  },

});
