
import {defineComponent, ref, watch,} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";


export default defineComponent({
  name: "payment-distribution",
  components: {
  },

  props: {
    paymentID: String,
  },



  setup(props) {
    const arrReservationPaymentsList = ref([]);

    watch(
        () => props.paymentID,
        () => {
          store.dispatch(Actions.RESERVATION_PAYMENTS_LIST, {'PaymentID':props.paymentID}).then((prmData) => {
            console.log("PaymentDis prm Data :" + JSON.stringify(prmData));
            arrReservationPaymentsList.value = prmData.data;
          })
        }
    );






    return {
      dateConvert,
      arrReservationPaymentsList,

    };
  },
});
