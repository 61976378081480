
import {defineComponent, ref, computed, watch} from "vue";
import TableContent from "@/components/appcomps/tables/TableContent.vue";
import TableFooter from "@/components/appcomps/tables/footer/TableFooter.vue";


export default defineComponent({
    name: "table-organizer",
    components: {
        TableFooter,
        TableContent,
    },
    props :{
        idcode: {type: String, required: true},
        header: { type: Array, required: true },
        data: { type: Array, required: true },
        itemsPerPage: { type: Number, default: 10 },
        total: { type: Number, required: true },
        page: { type: Number, required: true },
        itemsPerPageDropdownEnabled: {type: Boolean, required: false, default: true,},
    },
    emits: ["page-change", "on-sort", "on-items-select", "on-items-per-page-change", "on-rows-update"],
    setup(props, { emit }) {
        const currentPage = ref(1);
        const itemsInTable = ref<number>(props.itemsPerPage);

        watch(
            () => itemsInTable.value,
            (val) => {
                currentPage.value = 1;
                console.log("TAbleOrg on-items-per-page-change ************* *****************");
                emit("on-items-per-page-change", val);
            }
        );

        const pageChange = (prmPage: number) => {
            currentPage.value = prmPage;

            let vvOffset = (prmPage-1) * props.itemsPerPage;
            console.log("TableOrg pageChange itemsPerPage: "+props.itemsPerPage);
            emit("page-change", prmPage, vvOffset, props.itemsPerPage);

        };

        const dataToDisplay = computed(() => {
            /*
            if (props.data) {
                if (props.data.length <= itemsInTable.value) {
                    return props.data;
                } else {
                    let sliceFrom = (currentPage.value - 1) * itemsInTable.value;
                    return props.data.slice(sliceFrom, sliceFrom + itemsInTable.value);
                }
            }
             */
            return [];
        });

        const totalItems = computed(() => {
            return props.total;
            /*
            if (props.data) {
                if (props.data.length <= itemsInTable.value) {
                    return props.total;
                } else {
                    return props.data.length;
                }
            }
            return 0;
             */
        });

        const onRowsUpdate = () => {
            console.log("TableOrg RowsUpedate");
            emit("on-rows-update");
        };

        return {
            pageChange,
            totalItems,
            itemsInTable,
            onRowsUpdate,
        };
    }
});

