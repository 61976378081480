

import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Field} from "vee-validate";
import {commonf} from "@/core/filters/common";
import reservationProductType from "@/core/data/reservationProductsTypetypes";
import reservationConfirmTypes from "@/core/data/reservationConfirmType";
import {utils, writeFileXLSX} from "xlsx";


interface srcItem {
  srcFromDate: string;
  srcToDate: string;
  srcStartDate1: string;
  srcStartDate2: string;
  srcFinishDate1: string;
  srcFinishDate2: string;
  srcAccount: number;
  srcDepartment: number,
  srcMarket: number;
  srcPNR: string;
  srcVoucherNo: string;
  srcAccountVoucherNo: string;
  srcProductType: string;
}

export default defineComponent({
  name: "product-list",
  components: {
    Field,
  },
  setup() {

    onMounted(()=>{
      setDatePicker();
      store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
      store.dispatch(Actions.MARKETS_LIST_CACHE, {});
      click_Search();
    });

    const srcItem = ref<srcItem>({
      srcFromDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcToDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcStartDate1: "",
      srcStartDate2: "",
      srcFinishDate1: "",
      srcFinishDate2: "",
      srcAccount: 0,
      srcDepartment: 0,
      srcMarket: 0,
      srcPNR: "",
      srcVoucherNo: "",
      srcAccountVoucherNo: "",
      srcProductType: "HOTEL",
    });

    var fpickStartDate1;
    var fpickStartDate2;
    var fpickFinishDate1;
    var fpickFinishDate2;

    function setDatePicker() {
      let tmpSrcFromDate = flatpickr("#srcFromDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcToDate = flatpickr("#srcToDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});

      fpickStartDate1 = flatpickr("#scr_ReservationsList_StartDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickStartDate2 = flatpickr("#scr_ReservationsList_StartDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });

      fpickFinishDate1 = flatpickr("#scr_ReservationsList_FinishDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickFinishDate2 = flatpickr("#scr_ReservationsList_FinishDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
    }

    const myProductList = computed(() => {
      return store.getters.reservationProductsList2;
    });

    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    const myDepartments = computed(() => {
      return store.getters.DepartmentsList;
    });

    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });

    const myAccountsTitle = computed(() => {
      let tmpArray = [];
      if (myAccountList.value.data !== undefined) {
        for (var i of myAccountList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });

    const getAccounts = () => {
      let myAccounts = document.getElementById("srcAccounts") as HTMLInputElement;
      let myPayload = {};
      if (myAccounts.value.length == 3) {
        myPayload = {
          'Title': myAccounts.value
        }
        store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
      }
    }
    const filterAccounts = (val) => {
      if (myAccountList.value.data != null) {
        return (myAccountList.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return true;
      }
    };

    const click_Search = () => {
      let myAccount = document.getElementById("srcAccounts") as HTMLInputElement;
      const payloadProductList = {
        ReservationID: "",
        ProductType: srcItem.value.srcProductType,
       // is_active: "active",
        //ProcessDate: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
        ProcessDateStart: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
        ProcessDateFinish: srcItem.value.srcToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcToDate) : "",
        StartDate1: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate1, 0),
        StartDate2: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate2, 0),
        FinishDate1: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate1, 0),
        FinishDate2: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate2, 0),
        VoucherNo: srcItem.value.srcVoucherNo,
        AccountVoucherNo: (srcItem.value.srcAccountVoucherNo).replace(/\\/g, ''),
        // AccountVoucherNo:  (typeof srcItem.value.srcAccountVoucherNo !="string") ? (JSON.stringify(srcItem.value.srcAccountVoucherNo)).replace(/\\/g, '') : '""',

        Department: srcItem.value.srcDepartment,
        Market: srcItem.value.srcMarket,
        PNR: srcItem.value.srcPNR,
        //ProductType: "FLIGHT",
        is_active: "active",
        AccountID: myAccount.value ? filterAccounts(myAccount.value)[0].ID : "",

      }
      store.dispatch(Actions.RESERVATION_PRODUCTS_LIST_2, payloadProductList);

    }

    function click_ExportToExcel() {
      exportToExcel();
    }

    function exportToExcel() {
      const ws1 = utils.json_to_sheet(convertExcelSheet(myProductList.value.data));
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws1, "Liste");
      writeFileXLSX(wb, "Reservation_List.xlsx");
    }

    function convertExcelSheet(prmReservationList) {
      let rv=[];
      for (let i=0; i<prmReservationList.length; i++) {
        let eachReservationList = prmReservationList[i];
        let vvNew = {
          "İşlem Tarihi": eachReservationList.created_at,
          "Voucher No": eachReservationList.VoucherNo,
          "Acente Voucher No": eachReservationList.AccountVoucherNo,
          "Misafir": libTools.method.getAllPaxNameSurname(libTools.method.getRemarksJSONPaxes(eachReservationList.RemarksJSON1)),
          "Başlık": eachReservationList.Title,
          "Giriş Tarihi": dateConvert.method.convertToDateFormated(eachReservationList.StartDate, 'DD-MM-YYYY'),
          "Çıkış Tarihi": dateConvert.method.convertToDateFormated(eachReservationList.FinishDate, 'DD-MM-YYYY'),
          "Cari Hesap": eachReservationList.AccountTitle,
          "Maliyet": parseFloat(eachReservationList.TotalCurCost),
          "Satış": parseFloat(eachReservationList.TotalPrice),
          "Kur": eachReservationList.Cur,
          "Kullanıcı Adı": eachReservationList.Username,
          "Durum": libTools.method.getCoreDataByValue(reservationConfirmTypes.TYPES, eachReservationList.RemarksEnum1).Title,

        };
        rv.push(vvNew);
      }
      return rv;
    }


    return {
      dateConvert,
      libTools,
      commonf,
      MasterConfig,
      reservationProductType,
      reservationConfirmTypes,
      srcItem,
      myProductList,
      myAccountList,
      myDepartments,
      myMarkets,
      myAccountsTitle,
      click_Search,
      getAccounts,
      click_ExportToExcel,

    };
  },
});
