import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row w-100 mt-5 mx-auto p-5 d-flex align-items-center justify-content-between shadow-sm rounded border border-1 border-gray-300 border-hover" }
const _hoisted_2 = { class: "col-6 fw-bold text-start " }
const _hoisted_3 = { class: "text-dark fw-bolder  fs-7 mb-1" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-400 fw-bold fs-8"
}
const _hoisted_5 = {
  key: 1,
  class: "text-gray-400 fw-bold fs-8"
}
const _hoisted_6 = {
  key: 2,
  class: "text-gray-400 fw-bold fs-8"
}
const _hoisted_7 = { class: "col-3 text-primary fw-bolder d-block fs-7 " }
const _hoisted_8 = { class: "col-3 form-check form-check-custom form-check-solid w-20px col col-1" }
const _hoisted_9 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("fieldset", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.propTransferPrice.Transfer), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propTransferPrice.PaxPrice, (item, key) => {
          return (_openBlock(), _createElementBlock("div", { key: key }, [
            (item.ADT!==undefined)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.libTools.method.getPaxTypeName("ADT")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(item.ADT.AccountPrice) + "₺", 1)
                ]))
              : _createCommentVNode("", true),
            (item.CHD!==undefined)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.libTools.method.getPaxTypeName("CHD")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(item.CHD.AccountPrice) + "₺", 1)
                ]))
              : _createCommentVNode("", true),
            (item.INF!==undefined)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.libTools.method.getPaxTypeName("INF")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(item.INF.AccountPrice) + "₺", 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.propTransferPrice.AccountPrice) + "₺ ", 1),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("input", {
          class: "form-check-input border border-primary w-20px h-20px",
          type: "radio",
          name: `radio_Transfer_Price_${_ctx.propDirection}`,
          value: "",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click_TransferPrice && _ctx.click_TransferPrice(...args)))
        }, null, 8, _hoisted_9)
      ])
    ])
  ]))
}