
import {defineComponent} from "vue";

export default defineComponent({
    name: "table-head-row",
    props : {
        idcode: {type: String, required: true},
        header: { type: Array, required: true },
    },
    setup() {

        const getMinWith = (prmColumn) => {
            if (prmColumn!==undefined && (prmColumn as any).minWidth!==undefined) {
                return " min-width: "+prmColumn.minWidth+"px;"
            }
            return "";
        }

        return {
            getMinWith,
        };
    },
});

