import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableContent = _resolveComponent("TableContent")!
  const _component_TableFooter = _resolveComponent("TableFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TableContent, {
      idcode: _ctx.idcode,
      header: _ctx.header,
      data: _ctx.data,
      onOnRowsUpdate: _ctx.onRowsUpdate
    }, _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, name) => {
        return {
          name: name,
          fn: _withCtx(({ row: item }) => [
            _renderSlot(_ctx.$slots, name, { row: item })
          ])
        }
      })
    ]), 1032, ["idcode", "header", "data", "onOnRowsUpdate"]),
    _createVNode(_component_TableFooter, {
      onPageChange: _ctx.pageChange,
      count: _ctx.totalItems,
      itemsPerPage: _ctx.itemsPerPage,
      page: _ctx.page,
      "items-per-page-dropdown-enabled": _ctx.itemsPerPageDropdownEnabled
    }, null, 8, ["onPageChange", "count", "itemsPerPage", "page", "items-per-page-dropdown-enabled"])
  ], 64))
}