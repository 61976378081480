
import {computed, defineComponent, onMounted, ref} from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {dateConvert} from "@/core/appcore/libs/Lib_DateConvert";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import DatePickerDropdown from "@/components/dropdown/DatePickerDropdown.vue";

import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "dashboard",
    components: {
      DatePickerDropdown,

    },
    setup() {

      onMounted(() => {
        setCurrentPageTitle("Dashboard");
         setTimeout(setDate, 2000, 'today');
         setTimeout(setDate2, 2000, 'today');
        // setDate('thismonth');
      });

      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const submitButtonRef3 = ref<null | HTMLButtonElement>(null);

      const arrTotalsByAccount = ref ([]);
      const arrHotelTotalsByDashboard = ref ([]);
      const arrFlightTotalsByDashboard = ref ([]);
      const arrTransferTotalsByDashboard = ref ([]);
      const objTotalByHotelProducts = ref({HotelProductTotalPrice:0, HotelProductTotalProfit:0, HotelCur:"", });
      const objTotalByFlightProducts = ref({FlightProductTotalPrice:0, FlightProductTotalProfit:0, FlightCur:"", });
      const objTotalByTransferProducts = ref({TransferProductTotalPrice:0, TransferProductTotalProfit:0, TransferCur:"", });


      let theSelecteds = {
        MarketID: "1",
        ProcessDateStart: "",
        ProcessDateFinish: "",
      };

      let theSelectedsDashbaord = {
        MarketID: "1",
        ProcessDateStart: "",
        ProcessDateFinish: "",
      };

      const setDate = (date) => {
        if(arrTotalsByAccount.value!==undefined){
          if (!submitButtonRef.value) {
            return;
          }

          arrTotalsByAccount.value = [];
          console.log(date)
          const myfilterday = "spnFilterSrcDay";


          let mySrc;
          if (date == "today") {
            document.getElementById(myfilterday).innerText="Bugün";
            mySrc = {
              ProcessDateStart: dateConvert.method.getTodayYMD(),
              ProcessDateFinish: dateConvert.method.getTodayYMD(),
            }
          } else if (date == "yesterday") {
            document.getElementById(myfilterday).innerText="Dün";
            mySrc = {
              ProcessDateStart: dateConvert.method.getYesterdayYMD(),
              ProcessDateFinish: dateConvert.method.getYesterdayYMD(),
            }
          } else if (date == "last3days") {
            document.getElementById(myfilterday).innerText="Son 3 Gün";
            mySrc = {
              ProcessDateStart: dateConvert.method.addDayToDateYMD(dateConvert.method.getTodayYMD(), -2),
              ProcessDateFinish: dateConvert.method.getTodayYMD(),
            }
          } else if (date == "last7days") {
            document.getElementById(myfilterday).innerText="Son 7 Gün";
            mySrc = {
              ProcessDateStart: dateConvert.method.addDayToDateYMD(dateConvert.method.getTodayYMD(), -6),
              ProcessDateFinish: dateConvert.method.getTodayYMD(),
            }
          } else if (date == "thisweek") {
            document.getElementById(myfilterday).innerText="Bu Hafta";
            mySrc = {
              ProcessDateStart: dateConvert.method.getFirsDateOfWeekYMD(),
              ProcessDateFinish: dateConvert.method.getTodayYMD(),
            }
          } else if (date == "lastweek") {
            document.getElementById(myfilterday).innerText="Geçen Hafta";
            const weekValue = dateConvert.method.getLastWeekYMD();
            mySrc = {
              ProcessDateStart: weekValue.start,
              ProcessDateFinish: weekValue.finish
            }
          } else if (date == "last30days") {
            document.getElementById(myfilterday).innerText="Son 30 Gün";
            mySrc = {
              ProcessDateStart: dateConvert.method.addDayToDateYMD(dateConvert.method.getTodayYMD(), -29),
              ProcessDateFinish: dateConvert.method.getTodayYMD(),
            }
          } else if (date == "thismonth") {
            if(document.getElementById(myfilterday)!==null){
              document.getElementById(myfilterday).innerText="Bu Ay";
            }
            mySrc = {
              ProcessDateStart: dateConvert.method.getFirstDayOfMonthYMD(),
              ProcessDateFinish: dateConvert.method.getTodayYMD(),
            }
          } else if (date == "lastmonth") {
            document.getElementById(myfilterday).innerText="Geçen Ay";
            const weekValue = dateConvert.method.getLastMonthYMD();
            mySrc = {
              ProcessDateStart: weekValue.start,
              ProcessDateFinish: weekValue.finish
            }
          }
          else if (date.includes("custom")) {

            let arrDates= [];
            let arrMain= date.split("#");

            let stDates = arrMain[1];

            //  console.log("CUSTOM stDates: "+stDates+" inc: "+(stDates+"").includes(","));

            if ((typeof stDates)+"" === "object") {
              arrDates = stDates;
            } else if ((stDates+"").includes(",")===true) {
              arrDates = stDates.split(",");
            } else {
              arrDates = stDates.split(" - ");
            }

            if (arrDates[1]===undefined) {
              arrDates[1] = arrDates[0];
            }


            mySrc = {
              ProcessDateStart: arrDates[0],
              ProcessDateFinish: arrDates[1]
            }

            document.getElementById(myfilterday).innerText= dateConvert.method.convertToDate( mySrc.ProcessDateStart) + " / " + dateConvert.method.convertToDate( mySrc.ProcessDateFinish) + " arasında";
          }

          theSelecteds.ProcessDateStart = mySrc.ProcessDateStart;
          theSelecteds.ProcessDateFinish = mySrc.ProcessDateFinish;

          srcMaster();
        } else {
          arrTotalsByAccount.value = [];
        }

      }

      const setDate2 = (date) => {

        if (!submitButtonRef3.value) {
          return;
        }

        arrHotelTotalsByDashboard.value = [];
        arrFlightTotalsByDashboard.value = [];
        arrTransferTotalsByDashboard.value = [];
        objTotalByTransferProducts.value = {TransferProductTotalPrice:0, TransferProductTotalProfit:0, TransferCur: "", };
        objTotalByFlightProducts.value = {FlightProductTotalPrice:0, FlightProductTotalProfit:0, FlightCur:"", };
        objTotalByHotelProducts.value = {HotelProductTotalPrice:0, HotelProductTotalProfit:0, HotelCur:"", };
        console.log(date)
        const myfilterday = "spnFilterSrcDayDasboard";


        let mySrc;
        if (date == "today") {
          document.getElementById(myfilterday).innerText="Bugün";
          mySrc = {
            ProcessDateStart: dateConvert.method.getTodayYMD(),
            ProcessDateFinish: dateConvert.method.getTodayYMD(),
          }
        } else if (date == "yesterday") {
          document.getElementById(myfilterday).innerText="Dün";
          mySrc = {
            ProcessDateStart: dateConvert.method.getYesterdayYMD(),
            ProcessDateFinish: dateConvert.method.getYesterdayYMD(),
          }
        } else if (date == "last3days") {
          document.getElementById(myfilterday).innerText="Son 3 Gün";
          mySrc = {
            ProcessDateStart: dateConvert.method.addDayToDateYMD(dateConvert.method.getTodayYMD(), -2),
            ProcessDateFinish: dateConvert.method.getTodayYMD(),
          }
        } else if (date == "last7days") {
          document.getElementById(myfilterday).innerText="Son 7 Gün";
          mySrc = {
            ProcessDateStart: dateConvert.method.addDayToDateYMD(dateConvert.method.getTodayYMD(), -6),
            ProcessDateFinish: dateConvert.method.getTodayYMD(),
          }
        } else if (date == "thisweek") {
          document.getElementById(myfilterday).innerText="Bu Hafta";
          mySrc = {
            ProcessDateStart: dateConvert.method.getFirsDateOfWeekYMD(),
            ProcessDateFinish: dateConvert.method.getTodayYMD(),
          }
        } else if (date == "lastweek") {
          document.getElementById(myfilterday).innerText="Geçen Hafta";
          const weekValue = dateConvert.method.getLastWeekYMD();
          mySrc = {
            ProcessDateStart: weekValue.start,
            ProcessDateFinish: weekValue.finish
          }
        } else if (date == "last30days") {
          document.getElementById(myfilterday).innerText="Son 30 Gün";
          mySrc = {
            ProcessDateStart: dateConvert.method.addDayToDateYMD(dateConvert.method.getTodayYMD(), -29),
            ProcessDateFinish: dateConvert.method.getTodayYMD(),
          }
        } else if (date == "thismonth") {
          if(document.getElementById(myfilterday)!==null){
            document.getElementById(myfilterday).innerText="Bu Ay";
          }
          mySrc = {
            ProcessDateStart: dateConvert.method.getFirstDayOfMonthYMD(),
            ProcessDateFinish: dateConvert.method.getTodayYMD(),
          }
        } else if (date == "lastmonth") {
          document.getElementById(myfilterday).innerText="Geçen Ay";
          const weekValue = dateConvert.method.getLastMonthYMD();
          mySrc = {
            ProcessDateStart: weekValue.start,
            ProcessDateFinish: weekValue.finish
          }
        }
        else if (date.includes("custom")) {

          let arrDates= [];
          let arrMain= date.split("#");

          let stDates = arrMain[1];

          //  console.log("CUSTOM stDates: "+stDates+" inc: "+(stDates+"").includes(","));

          if ((typeof stDates)+"" === "object") {
            arrDates = stDates;
          } else if ((stDates+"").includes(",")===true) {
            arrDates = stDates.split(",");
          } else {
            arrDates = stDates.split(" - ");
          }

          if (arrDates[1]===undefined) {
            arrDates[1] = arrDates[0];
          }


          mySrc = {
            ProcessDateStart: arrDates[0],
            ProcessDateFinish: arrDates[1]
          }

          document.getElementById(myfilterday).innerText= dateConvert.method.convertToDate( mySrc.ProcessDateStart) + " / " + dateConvert.method.convertToDate( mySrc.ProcessDateFinish) + " arasında";
        }

        theSelectedsDashbaord.ProcessDateStart = mySrc.ProcessDateStart;
        theSelectedsDashbaord.ProcessDateFinish = mySrc.ProcessDateFinish;

        srcMasterHotelDashBoard();
        srcMasterFlightDashBoard();
        srcMasterTransferDashBoard();
      }

      const srcMaster = () => {

        const payloadTotalByAccounts = {
          Market : theSelecteds.MarketID,
          ProcessDateStart: theSelecteds.ProcessDateStart,
          ProcessDateFinish: theSelecteds.ProcessDateFinish,
        }

        store.dispatch(Actions.RESERVATIONS_TOTALS_BY_ACCOUNTS, payloadTotalByAccounts).then((prmData) => {
          arrTotalsByAccount.value = prmData;
          submitButtonRef.value.disabled = false;
          submitButtonRef.value.setAttribute("data-kt-indicator", "off");
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      }


      const srcMasterHotelDashBoard = () => {
        const payloadHotelTotalByDashboard = {
          Market : theSelectedsDashbaord.MarketID,
          ProductType :"HOTEL",
          ProcessDateStart: theSelectedsDashbaord.ProcessDateStart,
          ProcessDateFinish: theSelectedsDashbaord.ProcessDateFinish,
        }

        store.dispatch(Actions.RESERVATION_TOTALS_BY_DASHBOARD, payloadHotelTotalByDashboard).then((prmData) => {
          arrHotelTotalsByDashboard.value = prmData.data;
          sumHotelProduct();
          submitButtonRef3.value.disabled = false;
          submitButtonRef3.value.setAttribute("data-kt-indicator", "off");
        });
        submitButtonRef3.value.disabled = false;
        submitButtonRef3.value.setAttribute("data-kt-indicator", "on");
      }


      function sumHotelProduct() {
        if(arrHotelTotalsByDashboard.value!==undefined){
          for(let i=0; i<arrHotelTotalsByDashboard.value.length; i++){
            let each = arrHotelTotalsByDashboard.value[i];
            if(each.ProductType==="HOTEL"){
              console.log("sumHotelProduct " + each.ProductType==="HOTEL");
              objTotalByHotelProducts.value.HotelProductTotalPrice += parseFloat(each.TotalAccountPrice);
              objTotalByHotelProducts.value.HotelProductTotalProfit += parseFloat(each.TotalProfit);
              objTotalByHotelProducts.value.HotelCur = each.Cur;
            }

          }
        }else{
          arrHotelTotalsByDashboard.value =[];
        }

      }

      const srcMasterFlightDashBoard = () => {
        const payloadFlightTotalByDashboard = {
          Market : theSelectedsDashbaord.MarketID,
          ProductType :"FLIGHT",
          ProcessDateStart: theSelectedsDashbaord.ProcessDateStart,
          ProcessDateFinish: theSelectedsDashbaord.ProcessDateFinish,
        }

        store.dispatch(Actions.RESERVATION_TOTALS_BY_DASHBOARD, payloadFlightTotalByDashboard).then((prmData) => {
          arrFlightTotalsByDashboard.value = prmData.data;
          sumFlightProduct();
          submitButtonRef3.value.disabled = false;
          submitButtonRef3.value.setAttribute("data-kt-indicator", "off");
        });
        submitButtonRef3.value.disabled = false;
        submitButtonRef3.value.setAttribute("data-kt-indicator", "on");
      }

      function sumFlightProduct() {
        if(arrFlightTotalsByDashboard.value!==undefined){
          for(let i=0; i<arrFlightTotalsByDashboard.value.length; i++){
            let each = arrFlightTotalsByDashboard.value[i];
            if(each.ProductType==="FLIGHT"){
              objTotalByFlightProducts.value.FlightProductTotalPrice += parseFloat(each.TotalAccountPrice);
              objTotalByFlightProducts.value.FlightProductTotalProfit += parseFloat(each.TotalProfit);
              objTotalByFlightProducts.value.FlightCur = each.Cur;
            }

          }
        }else{
          arrFlightTotalsByDashboard.value =[];
        }

      }

      const srcMasterTransferDashBoard = () => {
        const payloadTransferTotalByDashboard = {
          Market : theSelectedsDashbaord.MarketID,
          ProductType :"TRANSFER",
          ProcessDateStart: theSelectedsDashbaord.ProcessDateStart,
          ProcessDateFinish: theSelectedsDashbaord.ProcessDateFinish,
        }

        store.dispatch(Actions.RESERVATION_TOTALS_BY_DASHBOARD, payloadTransferTotalByDashboard).then((prmData) => {
          arrTransferTotalsByDashboard.value = prmData.data;
          sumTransferProduct();
          submitButtonRef3.value.disabled = false;
          submitButtonRef3.value.setAttribute("data-kt-indicator", "off");
        });
        submitButtonRef3.value.disabled = false;
        submitButtonRef3.value.setAttribute("data-kt-indicator", "on");
      }

      function sumTransferProduct() {
        if(arrTransferTotalsByDashboard.value!==undefined){
          for(let i=0; i<arrTransferTotalsByDashboard.value.length; i++){
            let each = arrTransferTotalsByDashboard.value[i];
            if(each.ProductType==="TRANSFER"){
              objTotalByTransferProducts.value.TransferProductTotalPrice += parseFloat(each.TotalAccountPrice);
              objTotalByTransferProducts.value.TransferProductTotalProfit += parseFloat(each.TotalProfit);
              objTotalByTransferProducts.value.TransferCur = each.Cur;
            }

          }
        } else{
          arrTransferTotalsByDashboard.value =[];
        }

      }


      store.dispatch(Actions.MARKETS_LIST_CACHE, {});
      const lstMarketsList = computed(() => {
        return store.getters.MarketsListCache;
      });


      function change_Market() {
        arrTotalsByAccount.value = [];
        let selMarket = document.getElementById("sel_TotalByAccount_Market") as HTMLInputElement;

        if (selMarket!==null) {
          let vvValue = selMarket.value;
          theSelecteds.MarketID = vvValue;
          srcMaster();
        }
      }


      function change_Dashboard_Market() {
        arrHotelTotalsByDashboard.value = [];
        arrFlightTotalsByDashboard.value = [];
        arrTransferTotalsByDashboard.value = [];
        objTotalByTransferProducts.value = {TransferProductTotalPrice:0, TransferProductTotalProfit:0, TransferCur: "", };
        objTotalByFlightProducts.value = {FlightProductTotalPrice:0, FlightProductTotalProfit:0, FlightCur:"", };
        objTotalByHotelProducts.value = {HotelProductTotalPrice:0, HotelProductTotalProfit:0, HotelCur:"", };
        let selMarket = document.getElementById("sel_TotalByDashboard_Market") as HTMLInputElement;

        if (selMarket!==null) {
          let vvValue = selMarket.value;
          theSelectedsDashbaord.MarketID = vvValue;
          srcMasterHotelDashBoard();
          srcMasterTransferDashBoard();
          srcMasterFlightDashBoard();
        }
      }


      /*store.dispatch(Actions.RESERVATION_TOTALS_BY_DASHBOARD,  {ProductType :"", Market: ""}).then((prmData) => {
        arrTotalsByDashboard.value = prmData.data;
      });*/


      /*function getProductsByProductType (prmProductType){
        const confirmResults = arrTotalsByDashboard.value.filter(obj =>{
          return obj.ProductType === prmProductType + "";
        })
        return confirmResults;
      };*/


      return {
        arrTotalsByAccount,
        arrHotelTotalsByDashboard,
        arrFlightTotalsByDashboard,
        arrTransferTotalsByDashboard,
        libTools,
        setDate, srcMaster, setDate2,
        change_Market, change_Dashboard_Market, lstMarketsList,
        //getProductsByProductType,
        objTotalByHotelProducts,
        objTotalByTransferProducts,
        objTotalByFlightProducts,
        submitButtonRef,
        submitButtonRef3,


      };

    },
});
