import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface ReservationsInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class ReservationsModule extends VuexModule implements ReservationsInfo {
    errors = [];
    myList = {} as myvar;
    myList2 = {} as myvar;
    myDetails = [];

    get reservationsList(): myvar {
        return this.myList;
    }

    get reservationsList2(): myvar {
        return this.myList2;
    }

    get reservationsDetails(): Array<string> {
        return this.myDetails;
    }

    get getReservationsErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.RESERVATIONS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_RESERVATIONS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_RESERVATIONS_LIST2](payload) {
        this.myList2 = payload;
    }

    @Mutation
    [Mutations.SET_RESERVATIONS_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.RESERVATIONS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_RESERVATIONS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_LIST2](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/getList2", payload)
                .then(({ data }) => {
                    //console.log("Reservation Modules reservation getlist 2 " + JSON.stringify(data));
                   // console.log("Reservation Modules reservation getlist 2 " + JSON.stringify(data));
                    payload.data = data.data;
                    payload.stats = data.stats;
                   // console.log("Reservation Modules reservation getlist 2 payload all " + JSON.stringify(payload));
                    this.context.commit(Mutations.SET_RESERVATIONS_LIST2, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_NONINVOICES_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/getNonInvoicesList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }


    @Action
    [Actions.RESERVATIONS_NONPAYMENT_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/getNonPaymentList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_TOTALS_BY_ACCOUNTS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/getTotalsByAccounts", payload)
                .then(({ data }) => {
                    payload = data.data;
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }


    @Action
    [Actions.RESERVATIONS_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_RESERVATIONS_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_ALL_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/getReservationDetails", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_RESERVATIONS_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: REZERVASYON Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {

                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: REZERVASYON güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: REZERVASYON Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_KPLUS_PROCESS_START](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservationskplusprocess/startProcess", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_KPLUS_PROCESS_START_BOOKING](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservationskplusprocess/startBookingProcess", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_PRODUCT_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/insertProduct", credentials)
                //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        const payload = {
                            ReservationID: credentials.ReservationID,
                            ProductType: credentials.ProductType,
                        };
                        store.dispatch(Actions.RESERVATION_PRODUCTS_LIST, payload);
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: REZERVASYON Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_PRODUCT_UPDATE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/updateProduct", credentials)
                //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        const payload = {
                            ReservationID: credentials.ReservationID,
                            ProductType: credentials.ProductType,
                        };
                        store.dispatch(Actions.RESERVATION_PRODUCTS_LIST, payload);
                        resolve(data);
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                        reject(data);
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: REZERVASYON Güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_PRODUCT_DELETE](prmPayload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/deleteProduct", prmPayload)
                .then(({ data }) => {
                    if(data.status == 200) {
                        const payload = {
                            ReservationID: prmPayload.ReservationID,
                            ProductType: prmPayload.ProductType,
                        };
                        store.dispatch(Actions.RESERVATION_PRODUCTS_LIST, payload);
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: REZERVASYON Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATIONS_CALC_DEPTS](prmPayload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservations/calcDepts", prmPayload)
                .then(({ data }) => {
                    //
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: REZERVASYON CalcDept işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.RESERVATIONS_ERROR, myError);
                    reject();
                });
        });
    }

}