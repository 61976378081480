
import {defineComponent,} from "vue";


export default defineComponent({
  name: "add-collection-with-invoices",
  components: {
  },

  setup() {



    return {

    };
  },
});
