
import {defineComponent, ref, watch, onMounted, computed, WritableComputedRef,} from "vue";
import TablePagination from "@/components/appcomps/tables/footer/TablePagination.vue";

export default defineComponent({
    name: "table-footer",
    components: {
        TablePagination,
    },
    props: {
        count: { type: Number, required: false, default: 5 },
        page: { type: Number, required: true },
        itemsPerPage: { type: Number, default: 20 },
        itemsPerPageDropdownEnabled: { type: Boolean, required: false, default: true,},
    },
    emits: ["update:itemsPerPage", "page-change"],
    setup(props, { emit }) {

        const inputItemsPerPage = ref(5);

        onMounted(() => {
            inputItemsPerPage.value = props.itemsPerPage;
        });

        const pageChange = (newPage: number) => {
            emit("page-change", newPage);
        };

        const pageCount = computed(() => {
            return Math.ceil(props.count / props.itemsPerPage);
        });

        return {
            pageChange,
            pageCount,
            inputItemsPerPage,
        };
    }
});
