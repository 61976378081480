import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row m-0 p-0 pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TablePagination = _resolveComponent("TablePagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TablePagination, {
      totalPages: _ctx.pageCount,
      total: _ctx.count,
      perPage: _ctx.itemsPerPage,
      currentPage: _ctx.page,
      onPageChange: _ctx.pageChange
    }, null, 8, ["totalPages", "total", "perPage", "currentPage", "onPageChange"])
  ]))
}