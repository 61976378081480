const countries = {
    "TYPES" : [
        {Value : '1', Title : 'Türkiye', },
        {Value : '3', Title : 'İngiltere', },
        {Value : '4', Title : 'Fransa', },
        {Value : '5', Title : 'Ukrayna', },
        {Value : '6', Title : 'Rusya', },
        {Value : '7', Title : 'Hollanda', },
        {Value : '8', Title : 'Almanya', },
        {Value : '9', Title : 'İran', },
        {Value : '10', Title : 'Irak', },
        {Value : '11', Title : 'Suriye', },
        {Value : '12', Title : 'Suudi Arabistan', },
        {Value : '13', Title : 'İsrail', },
        {Value : '14', Title : 'Slovakya', },
        {Value : '15', Title : 'Macaristan', },
        {Value : '16', Title : 'Çekya', },
        {Value : '17', Title : 'ABD', },
        {Value : '18', Title : 'Yunanistan', },
        {Value : '19', Title : 'Kıbrıs Cumhuriyeti', },
        {Value : '20', Title : 'Bulgaristan', },
        {Value : '21', Title : 'Çin', },
        {Value : '22', Title : 'Japonya', },
        {Value : '23', Title : 'İsviçre', },
        {Value : '24', Title : 'İsveç', },
        {Value : '25', Title : 'Danimarka', },
        {Value : '26', Title : 'Romanya', },
        {Value : '27', Title : 'Azerbaycan', },
        {Value : '28', Title : 'Lübnan', },
        {Value : '29', Title : 'Kore', },
        {Value : '30', Title : 'İtalya', },
    ],
};
export default countries;
