
import {computed, defineComponent, onMounted, ref, watch,} from "vue";
//import collectionPaymentMethods from "@/core/data/collectionPaymentMethods";
import paymentMethods from "@/core/data/paymentMethods";
import {Field} from "vee-validate";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';


interface addItem {
  PaymentDate : string;
  PaymentAmount : string;
  PaymentType : string;

}

export default defineComponent({
  name: "add-collection",
  components: {
    Field,
  },
  methods: {
    changePaymentType() {
      if(this.addItem.PaymentType==="1"){
        (document.getElementById("divPosCollection") as HTMLElement).style.setProperty("display", "none", "important");
        (document.getElementById("divBankCollection") as HTMLElement).style.setProperty("display", "none", "important");
      }
      if(this.addItem.PaymentType==="2"){
        (document.getElementById("divPosCollection") as HTMLElement).style.setProperty("display", "block", "important");
        (document.getElementById("divBankCollection") as HTMLElement).style.setProperty("display", "none", "important");
      }
      if(this.addItem.PaymentType==="3"){
        (document.getElementById("divPosCollection") as HTMLElement).style.setProperty("display", "none", "important");
        (document.getElementById("divBankCollection") as HTMLElement).style.setProperty("display", "block", "important");
      }
      if(this.addItem.PaymentType==="4"){
        (document.getElementById("divPosCollection") as HTMLElement).style.setProperty("display", "none", "important");
        (document.getElementById("divBankCollection") as HTMLElement).style.setProperty("display", "none", "important");
      }

      console.log('Selected Option:', this.addItem.PaymentType);
    },
  },

  props: {
    propsCheckedReservations: String,
  },



  setup(props) {



    onMounted(()=>{
      setDatePicker();
      getNewReservationArray();

    });

    const arrNewCheckedReservations = ref([]);

    const addItem = ref<addItem>({
      PaymentDate: "",
      PaymentAmount: "",
      PaymentType: "",
    });


    

    function getNewReservationArray() {
      arrNewCheckedReservations.value =[];
      if(props.propsCheckedReservations!==null){
        let calcRemainingAmount = Number(addItem.value.PaymentAmount) ;
        for (let i=0; i<props.propsCheckedReservations.length; i++ ){
          let eachReservation = props.propsCheckedReservations[i] as any;
          if(eachReservation.RemainingAmount <=  calcRemainingAmount){
            eachReservation.Amount = eachReservation.RemainingAmount;
            calcRemainingAmount -= eachReservation.Amount;
          }else {
            eachReservation.Amount = calcRemainingAmount;
            calcRemainingAmount -= eachReservation.Amount;
          }
          arrNewCheckedReservations.value.push(eachReservation);
        }
      }
    }


    function setDatePicker() {
      let tmpPaymentDate = flatpickr("#PaymentDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: '',});
    }

    function AddPayment() {
      if((addItem.value.PaymentDate)===""){
        libTools.method.swalError("Tamam", "Tahsilat Tarihi Seçiniz");
      } else if((addItem.value.PaymentAmount)===""){
        libTools.method.swalError("Tamam", "Tahsilat Tutarı Giriniz");
      } else if((addItem.value.PaymentType)===""){
        libTools.method.swalError("Tamam", "Tahsilat Yöntemi Seçiniz");
      }else {
        getNewReservationArray();
        const payloadAddPayment = {
          PaymentDate : dateConvert.method.flatpickerToDateYM(addItem.value.PaymentDate),
          Amount: addItem.value.PaymentAmount,
          Method: addItem.value.PaymentType,
          MethodID: "",
          AccountID:"1",
          DepartmentID:"1",
          Cur:"TRY",
          CurRate:"",
          Description:"",
          Reservations: arrNewCheckedReservations.value,

        }
        console.log("AddCollection AddPayment payload" + JSON.stringify(payloadAddPayment));

        store.dispatch(Actions.PAYMENT_INSERT,  payloadAddPayment).then((prmData) => {
          libTools.method.swalSuccess("Tamam", "Tahsilat başarıyla eklendi");
        }).catch((prmData) => {
          libTools.method.swalError("Tamam", "Hata Oluştu");
        });
      }

    }

    store.dispatch(Actions.BANKACCOUNTS_LIST, {});
    const databankAccountsList = computed(() => {
      return store.getters.bankAccountsList;
    });


    function selectBankAccount(prmID) {
      console.log("Add Colletion selectedBankAccount : " + prmID)
    }


    function showComp() {
      console.log("AddCollection show comp is start");
      arrNewCheckedReservations.value =[];
      addItem.value.PaymentAmount = "";
      addItem.value.PaymentDate = "";
      addItem.value.PaymentType= "";
      (document.getElementById("divPosCollection") as HTMLElement).style.setProperty("display", "none", "important");
      (document.getElementById("divBankCollection") as HTMLElement).style.setProperty("display", "none", "important");
    }

    return {
      libTools,
      dateConvert,
      //collectionPaymentMethods,
      paymentMethods,
      databankAccountsList,
      addItem,
      AddPayment,
      arrNewCheckedReservations,
      getNewReservationArray,
      showComp,
      selectBankAccount

    };
  },
});
