

import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import { dateConvert } from "@/core/filters/datatime"
import {Field} from "vee-validate";
import {commonf} from "@/core/filters/common";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import ReservationPreview from "@/components/reservations/ReservationPreview.vue";
import NewReservationModal from "@/components/general-modals/NewReservationModal.vue";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import ReservationListVoucherModal from "@/components/general-modals/ReservationListVoucherModal.vue";
import reservationConfirmTypes from "@/core/data/reservationConfirmType";
import {utils, writeFileXLSX} from "xlsx";
import TableOrganizer from "@/components/appcomps/tables/TableOrganizer.vue";


interface srcItem {
  srcFromDate: string;
  srcToDate: string;
  srcStartDate1: string;
  srcStartDate2: string;
  srcFinishDate1: string;
  srcFinishDate2: string;
  srcAccount: number;
  srcDepartment: number,
  srcMarket: number;
  srcPNR: string;
  srcVoucherNo: string;
  srcAccountVoucherNo: string;
  srcPaxName: string;
  srcPaxSurname: string;
  srcHotelID: string;
}

const __default__ = defineComponent({
  name: "hotel-list",
  components: {
    Field,
    ReservationPreview,
    //ReservationAddNewModal,
    NewReservationModal,
    ReservationListVoucherModal,
    TableOrganizer,
  },
  data: function () {
    return {
      imgUrl: '',
      searchName: '',
      sortField: '',
      sortType: '',
      filterField: ['Title'],
      filterFieldName: ['Voucher No','Satış','Cari Hesap'],
    }
  },
  computed: {
    /*filteredTableList: function () {
      if (this.myList.data !== undefined) {
        return this.myList.data;
      } else {
        return [];
      }
    },
      */

    filteredTableList: function () {
      if (this.arrTableList !== undefined){
        console.log("reservation list filtertable list  v1");
        return this.arrTableList.filter(filteredList =>
                (filteredList.AccountTitle !== undefined && !(filteredList.AccountTitle).toLowerCase().indexOf(this.searchName.toLowerCase()))||
                (filteredList.VoucherNo !== undefined && !(filteredList.VoucherNo).toLowerCase().indexOf(this.searchName.toLowerCase()))||
                (filteredList.HotelTitle !== undefined && !(filteredList.HotelTitle).toLowerCase().indexOf(this.searchName.toLowerCase()))
            //  (filteredList.ProductTitle !== undefined && !(( filteredList.ProductTitle as any).HOTEL).toLowerCase().indexOf(this.searchName.toLowerCase()))

            //!(systemuser.Lastname).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
            //!(systemuser.Username).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
            //!(systemuser.Email).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
            //!(systemuser.Mobile).toLowerCase().indexOf(this.searchName.toLowerCase())

        );
      }else{
        return [];
      }
    },

  },
  props: {
    widgetClasses: String,
  },
  methods: {
    ...dateConvert.method,

    sortData(attr, data) {
      if (this.sortField == '') {
        this.sortType = "asc";
      } else {
        this.sortType = this.sortField == attr && this.sortType == 'asc' ? "desc" : "asc";
      }
      this.sortField = attr;

      if (this.sortType == 'asc' || this.sortType == 'desc') {
        this[data].sort((a, b) => {
          let fa = '';
          let fb = '';
          if (typeof (attr) == 'string') {
            if (this.sortType == 'asc') {
              fa = a[attr].toLowerCase();
              fb = b[attr].toLowerCase();
            } else {
              fb = a[attr].toLowerCase();
              fa = b[attr].toLowerCase();
            }
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            return this.sortType == 'asc' ? (a.Mobile - b.Mobile) : (b.Mobile - a.Mobile);
          }
        });
      }
    }
  },
  setup() {

    const srcItem = ref<srcItem>({
      srcFromDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcToDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcStartDate1: "",
      srcStartDate2: "",
      srcFinishDate1: "",
      srcFinishDate2: "",
      srcAccount: 0,
      srcDepartment: 0,
      srcMarket: 0,
      srcPNR: "",
      srcVoucherNo: "",
      srcAccountVoucherNo: "",
      srcPaxName: "",
      srcPaxSurname: "",
      srcHotelID: "",
    });

    var ccvLimit = ref(50);
    var ccvOffset = ref(0);
    var ccvPage = ref(1);
    var ccvTableStats = ref({});
    var ccvRequestCode = ref("ReservationList");
    var arrTableList = ref([]);
    var valStyle_TableOrg_Th_Border = ref("2px solid#ececec");
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    var lstHeader = ref( [
      { columnName: "RowIndex", columnLabel: "Sıra", sortEnabled: true, },
      { columnName: "ProcessDate", columnLabel: "İşlem Tarihi", sortEnabled: true, },
      { columnName: "VoucherNo", columnLabel: "Voucher No", sortEnabled: true, },
      { columnName: "AccountVoucherNo", columnLabel: "A.Voucher No", sortEnabled: true, },
      { columnName: "PaxName", columnLabel: "Misafir", sortEnabled: true, },
      { columnName: "Product", columnLabel: "Satış/Ürün", sortEnabled: true, },
      { columnName: "ReservationDate", columnLabel: "Rez.Tarihi", sortEnabled: true, },
      { columnName: "Account", columnLabel: "Cari Hesap", sortEnabled: true, },
      { columnName: "AccountAndCostPrice", columnLabel: "CH Fiyatı/Maliyet", sortEnabled: true, },
      { columnName: "Profit", columnLabel: "Kâr/Gizli Kaz.", sortEnabled: true, },
      { columnName: "PriceAndCommission", columnLabel: "Satış/Kom.", sortEnabled: true, },
      { columnName: "Payment", columnLabel: "Tahsilat", sortEnabled: true, },
      { columnName: "Status", columnLabel: "Durum", sortEnabled: true, },
      { columnName: "Action", columnLabel: "Aksiyon", sortEnabled: true, },
    ]);

    const pageChange = (prmPage: number, prmOffset: number, prmLimit: number) => {
      console.log("pageChange TableTest prmPage: "+prmPage+" prmOffset: "+prmOffset+" prmLimit: "+prmLimit);
      ccvOffset.value = prmOffset;
      sendReservationList(ccvRequestCode.value, prmPage, prmOffset, prmLimit);
    };

    const processTorgRowsUpdate = ()=>{

      const collTorgTr = document.getElementsByClassName("torg_tr_"+ccvRequestCode.value);
      console.log("processTorgVisualsUpdate collTorgTr.length: "+collTorgTr.length);
          for (let i=0; i<collTorgTr.length; i++) {
            let eachTr = collTorgTr[i] as HTMLElement;
            let vvRowIndex = Number(eachTr.getAttribute("data-rowindex"));

            //RowIndex
            const collRowIndex = eachTr.getElementsByClassName("special-rowindex");
            const collRowIndexContrainer = eachTr.getElementsByClassName("special-rowindexcontainer");
            if (collRowIndex.length > 0) {
              //collRowIndex[0].innerHTML = "" + Number(ccvOffset.value + vvRowIndex + 1);
              collRowIndex[0].innerHTML = "" + Number( Number((ccvTableStats.value as any).RowCount)- Number(ccvOffset.value + vvRowIndex + 0));
            }
          }
    }

    function setTableStats(prmTableStats) {
      ccvTableStats.value = prmTableStats;
      console.log("setTableStats started and payload stats = " + JSON.stringify(prmTableStats));
      //{"MinAmount":"5.00","TotalAmount":"1880.00","AverageAmount":"17.90","MaxAmount":"50.00","Length":105}
    }

    function sendReservationList(prmRequestCode, prmPage: number, prmOffset: number, prmLimit: number ) {
      if (!submitButtonRef.value) {
        return;
      }
      arrTableList.value=[];
      ccvOffset.value = prmOffset;
      ccvPage.value = prmPage;


      let myAccount = document.getElementById("srcAccounts") as HTMLInputElement;
      let myHotel = document.getElementById("srcHotels") as HTMLInputElement;
      const payload1 = {
        ProcessDateStart: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
        ProcessDateFinish: srcItem.value.srcToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcToDate) : "",
        StartDate1: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate1, 0),
        StartDate2: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate2, 0),
        FinishDate1: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate1, 0),
        FinishDate2: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate2, 0),
        VoucherNo: srcItem.value.srcVoucherNo,
        AccountVoucherNo: (srcItem.value.srcAccountVoucherNo).replace(/\\/g, ''),
        Department: srcItem.value.srcDepartment,
        Market: srcItem.value.srcMarket,
        PNR: srcItem.value.srcPNR,
        PaxName: srcItem.value.srcPaxName,
        PaxSurname: srcItem.value.srcPaxSurname,
        //HotelID: srcItem.value.srcHotelID,
        HotelID: myHotel.value ? filterHotels(myHotel.value)[0].ID : "",
        is_active: "active",
        AccountID: myAccount.value ? filterAccounts(myAccount.value)[0].ID : "",
        offset:prmOffset,
        limit:prmLimit,
        page:prmPage,
        requestcode:prmRequestCode,

      };
      console.log("click_Search payload1: "+JSON.stringify(payload1));
      store.dispatch(Actions.RESERVATIONS_LIST2, payload1).then((payload) => {
        console.log("Reservation List table Organizer Then 2  " + JSON.stringify(payload))
        if(payload.requestcode===ccvRequestCode.value){
          arrTableList.value = payload.data;
          setTableStats(payload.stats);
        }
        if(payload.requestcode===ccvRequestCode.value + "ExpToExcel"){
          exportToExcel(payload.data);
        }
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");

      }).catch((payload) => {
        libTools.method.swalError("Tamam", "Hata Oluştu");
      });
      submitButtonRef.value.disabled = false;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

    }

    function click_ExportToExcel() {
      console.log("click_ExportToExcel START");
      //sendReservationList(ccvRequestCode.value + "ExpToExcel", 1, 0, (ccvTableStats.value as any).RowCount);
      sendReservationList(ccvRequestCode.value + "ExpToExcel", 1, 0, 0);
    }


    function exportToExcel(prmReservationList) {
      let rv=[];
      for (let i=0; i<prmReservationList.length; i++) {
        let eachReservationList = prmReservationList[i];
        let vvNew = {
          "İşlem Tarihi": eachReservationList.created_at,
          "Voucher No": eachReservationList.VoucherNo,
          "Acente Voucher No": eachReservationList.AccountVoucherNo,
          "Müşteri": eachReservationList.PaxName + " " + eachReservationList.PaxSurname,
          "Satış / Ürün": getProductTitle(eachReservationList),
          "Giriş Tarihi": dateConvert.method.convertToDateFormated(eachReservationList.StartDate, 'DD-MM-YYYY'),
          "Çıkış Tarihi": dateConvert.method.convertToDateFormated(eachReservationList.FinishDate, 'DD-MM-YYYY'),
          "Cari Hesap": eachReservationList.AccountTitle,
          "CH Fiyatı": parseFloat(eachReservationList.TotalAccountPrice),
          "Maliyet": parseFloat(eachReservationList.TotalCurCost),
          "Kâr": parseFloat(eachReservationList.TotalProfit),
          "Gizli Kazanç": parseFloat(eachReservationList.SecretProfit),
          "Satış": parseFloat(eachReservationList.TotalPrice),
          "Komisyon": parseFloat(eachReservationList.TotalCommission),
          "Tahsilat": parseFloat(eachReservationList.TotalPayment),
          "Kur": eachReservationList.SaleCur,
          "Durum Otel": libTools.method.getCoreDataByValue(reservationConfirmTypes.TYPES, eachReservationList.ConfirmStatusHOTEL).Title,
          "Durum Genel": libTools.method.getCoreDataByValue(reservationConfirmTypes.TYPES, eachReservationList.ConfirmStatus).Title,

        };
        rv.push(vvNew);
      }

      const ws = utils.json_to_sheet(rv);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "ReservationList" + ".xlsx");
      return rv;
    }

    const dataHotelList = computed(() => {
      let dataComputed = store.getters.hotelListTo;
      if (dataComputed!==undefined) {
        return dataComputed;
      }
      return {data:[]};
    });



    onMounted(()=>{
      setDatePicker();
      store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
      store.dispatch(Actions.MARKETS_LIST_CACHE, {});
      click_Search();
     // sendReservationList(ccvRequestCode.value, 1, 0, ccvLimit.value);
    });


    const arrReservationList = ref([]);
    // const arrReservationProductConfirmList = ref([]);
    //const arrHotelList = ref([]);


    function fitReservationList(prmList) {
  /*    //console.log("ReservationList fitReservationList started");
      arrReservationList.value = [];
      for (let i=0; i<prmList.length; i++ ){
        let each = prmList[i];
        each.HotelTitle = getProductTitle(each)
        arrReservationList.value.push(each);
        // console.log("ReservationList fitReservationList " + JSON.stringify(each));
      }*/

    }

    const myList = computed(() => {
     /* //console.log("ReservationList myList v1");
      let dataComputed = store.getters.reservationsList2
      if (dataComputed!=undefined && dataComputed.data!==undefined) {
        //console.log("ReservationList myList v2");
        fitReservationList(dataComputed.data);
        // console.log("ReservationList myList v3");
      }
      return dataComputed.data;*/
      return [];
    });


    const myDepartments = computed(() => {
      return store.getters.DepartmentsList;
    });

    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });


    const myHotelsTitle = computed(() => {
      let tmpArray = [];
      if (dataHotelList.value.data !== undefined) {
        for (var i of dataHotelList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });

    const getHotels = () => {
      let myHotels = document.getElementById("srcHotels") as HTMLInputElement;
      let myPayload = {};
      if (myHotels.value.length == 3) {
        myPayload = {
          'Title': myHotels.value,
          'CallFrom': "TO",
        }
        store.dispatch(Actions.HOTEL_LIST_ALL_TO, myPayload);
      }
    }

    const filterHotels = (val) => {
      if (dataHotelList.value.data != null) {
        return (dataHotelList.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return true;
      }
    };


    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    const myAccountsTitle = computed(() => {
      let tmpArray = [];
      if (myAccountList.value.data !== undefined) {
        for (var i of myAccountList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });


    const getAccounts = () => {
      let myAccounts = document.getElementById("srcAccounts") as HTMLInputElement;
      let myPayload = {};
      if (myAccounts.value.length == 3) {
        myPayload = {
          'Title': myAccounts.value
        }
        store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
      }
    }

    const filterAccounts = (val) => {
      if (myAccountList.value.data != null) {
        return (myAccountList.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return true;
      }
    };

    var fpickStartDate1;
    var fpickStartDate2;
    var fpickFinishDate1;
    var fpickFinishDate2;

    function setDatePicker() {
      let tmpSrcFromDate = flatpickr("#srcFromDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcToDate = flatpickr("#srcToDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});

      fpickStartDate1 = flatpickr("#scr_ReservationsList_StartDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickStartDate2 = flatpickr("#scr_ReservationsList_StartDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });

      fpickFinishDate1 = flatpickr("#scr_ReservationsList_FinishDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickFinishDate2 = flatpickr("#scr_ReservationsList_FinishDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
    }

    function getProductTitle(prmEach) {

      if ((prmEach as any).ProductTitle!==null) {
        //console.log("getProductTitle ProductTitle: "+(prmEach as any).ProductTitle);
        let objProductTitle = JSON.parse((prmEach as any).ProductTitle);
        if ((objProductTitle as any).HOTEL!==undefined) {
          return (objProductTitle as any).HOTEL[0];
        }
        if ((objProductTitle as any).TRANSFER!==undefined) {
          return (objProductTitle as any).TRANSFER[0];
        }
      }

      return "";
    }

    function getProductCounts(prmEach, prmType) {
      if ((prmEach as any).ProductCounts!==null) {
        let objParsed = JSON.parse((prmEach as any).ProductCounts);

        if (prmType==="FLIGHT" && (objParsed as any).FLIGHT!==undefined) {
          return Number((objParsed as any).FLIGHT);
        }
        if (prmType==="HOTEL" && (objParsed as any).HOTEL!==undefined) {
          return Number((objParsed as any).HOTEL);
        }
        if (prmType==="TRANSFER" && (objParsed as any).TRANSFER!==undefined) {
          return Number((objParsed as any).TRANSFER);
        }
      }

      return "";
    }



    const click_Search = () => {
      sendReservationList(ccvRequestCode.value, 1, 0, ccvLimit.value);
    };


    let refComp_ReservationVoucherModal = ref(null);

    function clickShowVoucher(ID) {
      (refComp_ReservationVoucherModal.value as any).showComp(ID);
    }



    return {
      dateConvert,
      commonf,
      libTools,
      MasterConfig,
      srcItem,
      myAccountList,
      myAccountsTitle,
      getAccounts,
      myHotelsTitle,getHotels,
      click_Search,
      submitButtonRef,
      myDepartments,
      myMarkets,
      myList,
      getProductTitle,
      getProductCounts,
      clickShowVoucher,
      refComp_ReservationVoucherModal,
      arrReservationList,
      reservationConfirmTypes,
      click_ExportToExcel,
      ccvLimit, ccvOffset, ccvPage, ccvTableStats, ccvRequestCode, lstHeader, arrTableList, pageChange, processTorgRowsUpdate, sendReservationList, valStyle_TableOrg_Th_Border,

    }
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "041cf46c": (_ctx.valStyle_TableOrg_Th_Border)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__