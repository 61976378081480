
import {computed, defineComponent, onMounted, ref,} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import {Field} from "vee-validate";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";
import flatpickr from "flatpickr";
import {Turkish} from "flatpickr/dist/l10n/tr";
import 'flatpickr/dist/flatpickr.css';
import {utils, writeFileXLSX} from "xlsx";


interface intSearchVals {
  srcMarket: number;
  //srcProcessDateStart: string;
//  srcProcessDateFinish: string;
  srcAccount: number;
  srcYear: number;
}

export default defineComponent({
  name: "checkout-based-monthly-agency-report",
  components: {
    Field,
  },

  setup() {

    onMounted(() => {
      setDatePicker();
      search();
      setCurrentPageBreadcrumbs("Çıkış Bazlı Aylık Acente Raporu", ["Raporlar", "Liste"]);
    });

    //const arrTotalsByAccount = ref ([]);
    const arrCheckoutBasedAccountList = ref ([]);


    const intSearchVals = ref<intSearchVals>({
      srcMarket: 1,
      srcAccount: 0,
      srcYear: 2025,
     // srcProcessDateStart: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
    //  srcProcessDateFinish: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),


    });

    const objAmount = ref({Mounth1:0, Mounth2: 0, Mounth3:0, Mounth4:0, Mounth5:0, Mounth6:0, Mounth7:0, Mounth8:0, Mounth9:0, Mounth10:0, Mounth11:0, Mounth12:0, Cur:"",});

    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });

    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    const myAccountsTitle = computed(() => {
      let tmpArray = [];
      if (myAccountList.value.data !== undefined) {
        for (var i of myAccountList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });

    const getAccounts = () => {
      let myAccounts = document.getElementById("srcAccounts") as HTMLInputElement;
      let myPayload = {};
      if (myAccounts.value.length == 3) {
        myPayload = {
          'Title': myAccounts.value
        }
        store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
      }
    }

    function getTotals() {
      for(let i=0; i<arrCheckoutBasedAccountList.value.length; i++){
        let each = arrCheckoutBasedAccountList.value[i];
        objAmount.value.Mounth1 += parseFloat(each.TotalPrice01);
        objAmount.value.Mounth2 += parseFloat(each.TotalPrice02);
        objAmount.value.Mounth3 += parseFloat(each.TotalPrice03);
        objAmount.value.Mounth4 += parseFloat(each.TotalPrice04);
        objAmount.value.Mounth5 += parseFloat(each.TotalPrice05);
        objAmount.value.Mounth6 += parseFloat(each.TotalPrice06);
        objAmount.value.Mounth7 += parseFloat(each.TotalPrice07);
        objAmount.value.Mounth8 += parseFloat(each.TotalPrice08);
        objAmount.value.Mounth9 += parseFloat(each.TotalPrice09);
        objAmount.value.Mounth10 += parseFloat(each.TotalPrice10);
        objAmount.value.Mounth11 += parseFloat(each.TotalPrice11);
        objAmount.value.Mounth12 += parseFloat(each.TotalPrice12);
        objAmount.value.Cur = each.SaleCur;

      }

    }

    const filterAccounts = (val) => {
      if (myAccountList.value.data != null) {
        return (myAccountList.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return true;
      }
    };

    function setDatePicker() {
      let tmpSrcFromDate = flatpickr("#srcProcessDateStart", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcToDate = flatpickr("#srcProcessDateFinish", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
    }

    const search = () => {
      objAmount.value = {Mounth1:0, Mounth2: 0, Mounth3:0, Mounth4:0, Mounth5:0, Mounth6:0, Mounth7:0, Mounth8:0, Mounth9:0, Mounth10:0, Mounth11:0, Mounth12:0, Cur:"",};
      arrCheckoutBasedAccountList.value = [];
      let myAccount = document.getElementById("srcAccounts") as HTMLInputElement;
      const payload1 = {
        Market:intSearchVals.value.srcMarket,
        AccountIDs : [
            {ID:myAccount.value ? filterAccounts(myAccount.value)[0].ID : ""}
        ],
        Year:intSearchVals.value.srcYear,
      }

      store.dispatch(Actions.ACCOUNT_MOUNTHLY_LIST, payload1).then((prmData) => {
        arrCheckoutBasedAccountList.value = prmData;
        getTotals();
      });

    }

    function click_ExportToExcel() {
      exportToExcel();
    }

    function exportToExcel() {
      const ws1 = utils.json_to_sheet(convertExcelSheet(arrCheckoutBasedAccountList.value));
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws1, "Liste");
      writeFileXLSX(wb, "Agency_Based_Sales_Report.xlsx");
    }

    function convertExcelSheet(prmReports) {
      let rv=[];
      for (let i=0; i<prmReports.length; i++) {
        let eachReport = prmReports[i];
        let vvNew = {
          "Acente Adı": eachReport.AccountTitle,
          "Rezervasyon Sayısı": eachReport.ReservationCount,
          "Oda Sayısı": eachReport.RoomCount,
          "Bilet Sayısı": eachReport.FlightCount,
          "Transfer Sayısı": eachReport.TransferCount,
          "Toplam Maliyet Fiyatı": eachReport.TotalCurCost,
          "Toplam Acente Fiyatı": eachReport.TotalAccountPrice,
          "Toplam Satış Fiyatı": eachReport.TotalPrice,
          "Toplam Komisyon": eachReport.TotalCommission,
          "Toplam Kâr": eachReport.TotalProfit,
          "Kur": eachReport.SaleCur,
        };
        rv.push(vvNew);
      }
      return rv;
    }


    return {
      Turkish,
      MasterConfig,
      dateConvert,
      libTools,
      search,
      intSearchVals,
      //arrTotalsByAccount,
      myMarkets,
      getAccounts,
      myAccountList,
      objAmount,
      myAccountsTitle,
      click_ExportToExcel,
      arrCheckoutBasedAccountList,
    }
  },

});
